.page-work-inspection-listing-create-edit {
	width: 100%;
	max-width: $scr-w-md;

	.work-inspection-listing-create-edit {
		//
		&__header {
			display: flex;
			justify-content: space-between;

			@include max-width($scr-xs) {
				margin-bottom: 20px;
				align-items: flex-start;
				flex-direction: column;
			}

			&--column {
				flex-direction: column;
			}
		}

		&__header-buttons {
			gap: 10px;
			display: flex;
			flex-direction: row-reverse;

			.app-button {
				width: auto;
				padding: 8px 15px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__action-button {
			//
			.app-icon {
				transform: rotate(90deg);
			}
		}

		&__last-update {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-size: $text-size-xl;
		}
	}
}

.page-work-inspection-listing-edit-menu {
	//
	.MuiPaper-root {
		width: 100%;
		height: 100px;
		max-width: 120px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);
	}
}
