.page-delivery-orders-view-edit {
	width: 100%;
	max-width: $scr-w-md;

	.delivery-orders-view-edit {
		//
		&__header {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			@include max-width($scr-xs) {
				margin-bottom: 20px;
				align-items: flex-start;
				flex-direction: column;
			}

			&--column {
				flex-direction: column;
			}
		}

		&__header-buttons {
			display: flex;
			flex-direction: row-reverse;
			gap: 10px;

			.app-button {
				width: auto;
				padding: 8px 15px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__action-button {
			margin-left: 10px;

			.app-icon {
				transform: rotate(90deg);
			}
		}

		&__last-update {
			margin: 15px 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__title {
			color: $c-black;
			font-size: $text-size-xl;
		}

		&__container {
			margin-bottom: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__wrapper {
			padding: 25px;

			&--divider {
				border-bottom: 1px solid $c-border;
			}
		}

		&__label {
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;

			span {
				color: $c-red;
			}
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-button {
				width: calc(50% - 20px);
				padding: 12px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				align-self: center;

				&__label {
					color: $c-secondary;
				}
			}

			.app-input,
			.app-mobile-input,
			.app-select-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-button,
				.app-mobile-input,
				.app-select-input {
					width: 100%;
				}
			}
		}

		&__column {
			width: calc(50% - 20px);

			&--divider {
				margin-bottom: 25px;
				border-bottom: 1px solid $c-border;
			}

			.app-input,
			.app-select-input {
				width: 100%;
			}

			@include max-width($scr-xs) {
				width: 100%;
				flex-direction: column;
			}
		}

		&__column-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-input {
				width: calc(50% - 2px);

				& ~ .app-input {
					align-items: flex-end;
				}
			}

			@include max-width($scr-w-md) {
				width: 100%;
				flex-direction: column;

				.app-input {
					width: calc(100%);
				}
			}
		}

		&__description {
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__item-table {
			margin: 15px 0;
			background-color: $c-white;
			border: 3px solid $c-border;
			border-radius: 5px;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);

			.item-table {
				display: flex;
				flex-direction: row;
				border: 1px solid $c-header-blue;
				border-bottom: 0;

				&__content {
					flex: 1;
				}

				&__item {
					display: flex;
					flex-direction: row;
					border-bottom: 1px solid $c-header-blue;
				}

				&__label {
					width: 150px;
					margin: 0;
					padding: 15px;
					color: $c-grey;
					font-size: $text-size-s;
					border-right: 1px solid $c-header-blue;
				}

				&__value {
					@extend .item-table__label;
					flex: 1;
				}

				&__pagination {
					width: 70px;
					padding: 20px 10px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: space-between;
				}

				&__button {
					width: 35px;
					border: 0;
					cursor: pointer;
					background-color: transparent;

					&--prev {
						transform: rotate(270deg);

						&:disabled {
							opacity: 0.1;
						}
					}

					&--next {
						transform: rotate(90deg);

						&:disabled {
							opacity: 0.1;
						}
					}

					img {
						width: 100%;
					}
				}

				&__page {
					text-align: center;
					color: $c-light-grey;
					font-size: $text-size-s;
				}
			}
		}

		&__button-container {
			width: 575px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;
			gap: 15px;

			.app-button {
				//
				&--outline {
					background-color: transparent;
					border: 1px solid $c-secondary;

					.app-button__label {
						color: $c-secondary;
					}
				}
			}
		}

		&__cancel-button {
			border: 0 !important;
			background-color: transparent;

			.app-button__label {
				color: $c-primary !important;
			}
		}

		.app-table {
			margin: 15px 0;

			&__cell {
				border-bottom: 0 !important;

				.app-checkbox {
					margin-left: auto;
					margin-right: auto;
				}

				&--header {
					background-color: $c-header-blue;

					.app-checkbox__box {
						//
						svg {
							//
							path {
								stroke: $c-header-blue;
							}
						}
					}
				}
			}

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				&__price {
					text-align: right;

					span {
						justify-content: flex-end;
					}
				}

				&__text {
					color: $c-light-grey;
					font-size: $text-size-s;
				}

				&__expandable {
					display: flex;
					align-items: center;
				}

				&__expandable-button {
					border: 0;
					cursor: pointer;
					background-color: transparent;
					transform: rotate(90deg);

					&--expanded {
						transform: rotate(270deg);
					}
				}

				&__expandable-icon {
					width: 20px;
					height: 20px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url(../../../assets/images/chevron-right-light-blue-icon.svg);
				}

				&__sub-item {
					//
					.MuiTableCell-root {
						background-color: $c-pale-grey;
						border-bottom: 0;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}

				.MuiTableRow-root {
					//
					.MuiTableCell-root {
						//
						&:first-child {
							padding-right: 0;
							text-align: right;
						}
					}
				}

				.MuiTableCell-paddingCheckbox {
					display: none !important;
				}
			}
		}
	}
}

.delivery-orders-view-edit-menu {
	//
	.MuiPaper-root {
		width: 100%;
		max-width: 150px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.menu {
			display: flex;
			flex-direction: column;

			&__label {
				cursor: pointer;
				border: 0;
				padding: 8px 10px;
				font-size: $text-size-s;
				font-weight: $text-weight-normal;
				background-color: $c-white;
				text-align: start;
			}
		}
	}
}
