.app-edit-user-information-modal {
	display: flex;
	align-items: center;
	justify-content: center;

	.edit-user-information-modal {
		padding: 40px;
		border-radius: 8px;
		background-color: $c-white;

		&__header {
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
			text-align: center;
		}

		&__description {
			color: $c-dark-grey;
			text-align: center;
		}

		&__button-container {
			margin: 25px 0 0;
			gap: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			.app-button {
				//
				&--outline {
					border: 0;
					background: transparent;
				}
			}
		}
	}
}
