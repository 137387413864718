.app-customer-edit-asset-add-service-checklist-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.customer-edit-asset-add-service-checklist-modal {
		width: 100%;
		max-width: 1024px;
		max-height: 90%;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-l;
		}

		&__form {
			width: 100%;
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__button-container {
			max-width: 320px;
			margin: 30px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}

		.table-filter-header {
			margin: 20px 0;
			//
			.app-input {
				//
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-border;
				}
			}
		}
	}
}
