.app-icon {
	width: 24px;
	height: 24px;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;

	&__i {
		width: inherit;
		height: inherit;
	}
}
