.app-sales-order-details {
	width: 100%;
	max-width: $scr-w-md;

	.sales-order-details {
		//
		&__container {
			margin-bottom: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__wrapper {
			padding: 25px;

			&--divider {
				border-bottom: 1px solid $c-border;
			}
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&--general-details {
				flex-wrap: wrap;

				.app-button {
					width: calc(50% - 20px);
					padding: 12px;
					background-color: transparent;
					border: 1px solid $c-secondary;
					align-self: center;

					&__label {
						color: $c-secondary;
					}
				}
			}

			.app-input,
			.app-mobile-input,
			.app-select-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-button,
				.app-mobile-input,
				.app-select-input {
					width: 100%;
				}
			}
		}

		&__gap {
			display: flex;
			align-items: end;
			gap: 10px;

			@include max-width($scr-md) {
				flex-direction: column;
			}
		}

		&__column {
			width: calc(50% - 20px);

			&--divider {
				margin-bottom: 25px;
				border-bottom: 1px solid $c-border;
			}

			.app-input,
			.app-select-input {
				width: 100%;
			}

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__table-header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.sales-order-details {
				//
				&__label {
					margin: 0;
				}
			}

			.app-button {
				max-width: 110px;
				padding: 8px 10px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__item-table {
			margin: 15px 0;
			background-color: $c-white;
			border: 3px solid $c-border;
			border-radius: 5px;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);

			.item-table {
				display: flex;
				flex-direction: row;
				border: 1px solid $c-header-blue;
				border-bottom: 0;

				&__content {
					flex: 1;
				}

				&__item {
					display: flex;
					flex-direction: row;
					border-bottom: 1px solid $c-header-blue;
				}

				&__label {
					width: 150px;
					margin: 0;
					padding: 15px;
					color: $c-grey;
					font-size: $text-size-s;
					border-right: 1px solid $c-header-blue;
				}

				&__value {
					@extend .item-table__label;
					flex: 1;
				}

				&__pagination {
					width: 70px;
					padding: 20px 10px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: space-between;
				}

				&__button {
					width: 35px;
					border: 0;
					cursor: pointer;
					background-color: transparent;

					&--prev {
						transform: rotate(270deg);

						&:disabled {
							opacity: 0.1;
						}
					}

					&--next {
						transform: rotate(90deg);

						&:disabled {
							opacity: 0.1;
						}
					}

					img {
						width: 100%;
					}
				}

				&__page {
					text-align: center;
					color: $c-light-grey;
					font-size: $text-size-s;
				}
			}
		}

		&__button-container {
			width: 520px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					background-color: transparent;
					border: 1px solid $c-secondary;

					.app-button__label {
						color: $c-secondary;
					}
				}
			}
		}

		&__cancel-button {
			border: 0 !important;
			background-color: transparent;
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__expandable {
					display: flex;
					align-items: center;
				}

				&__expandable-button {
					border: 0;
					cursor: pointer;
					background-color: transparent;
					transform: rotate(90deg);

					&--expanded {
						transform: rotate(270deg);
					}
				}

				&__expandable-icon {
					width: 20px;
					height: 20px;
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					background-image: url("../../../../assets/images/chevron-right-light-blue-icon.svg");
				}

				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}

					&:disabled {
						cursor: not-allowed;
					}
				}

				&__numbering {
					text-align: center !important;
				}

				&__price {
					text-align: right;

					span {
						justify-content: flex-end;
					}
				}

				&__text {
					color: $c-light-grey;
					font-size: $text-size-s;
				}

				&__link {
					margin-left: 5px;
					color: $c-secondary;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
					cursor: pointer;
				}

				&__sub-item {
					//
					.MuiTableCell-root {
						background-color: $c-pale-grey;
						border-bottom: 0;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}

				.MuiTableRow-root {
					//
					.MuiTableCell-root {
						//
						&:first-child {
							padding-right: 0;
							text-align: right;
						}
					}
				}

				.MuiTableCell-paddingCheckbox {
					display: none !important;
				}
			}
		}
	}
}

.sales-order-create-edit-table-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}

	&__close-icon {
		transform: rotate(45deg);
	}
}
