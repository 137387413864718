.app-table-filter-header {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-between;
	gap: 10px;

	.table-filter-header {
		width: 100%;
		max-width: 550px;
		display: flex;
		flex-direction: row;

		.app-input {
			margin: 0;
			border-radius: 5px;
			background-color: $c-white;

			.MuiOutlinedInput-notchedOutline {
				border: 0;
			}
		}

		&__filter-button {
			margin: 0 10px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__create-button {
			//
			.app-button {
				max-width: 125px;
				padding: 8px 20px;
				flex-direction: row-reverse;
			}
		}
	}
}

.app-table-filter-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		@include max-width(500px) {
			width: calc(100% - 32px);
		}

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid $c-light-grey !important;
		}
	}

	.filter-menu {
		padding: 10px 20px;

		&__body {
			//
		}

		&__footer {
			padding: 15px 0 0;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		&__buttons {
			padding: 0 0 0 15px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		&__button {
			color: $c-red;
			font-size: $text-size-s;
			transition: $transition;
			border: 0;
			background-color: transparent;
			cursor: pointer;

			&:hover {
				color: darken($c-red, 10%);
			}

			& ~ .filter-menu__button {
				margin-left: 10px;
			}

			&--cancel {
				color: $c-light-grey;

				&:hover {
					color: darken($c-light-grey, 10%);
				}
			}

			&--apply {
				color: $c-secondary;

				&:hover {
					color: darken($c-secondary, 10%);
				}
			}
		}
	}
}
