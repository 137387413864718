.app-input-drag-and-drop-files {
	//
	.input-drag-and-drop-files {
		padding: 25px;
		background-color: $c-footer-grey;
		border-radius: 8px;
		box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		flex-direction: column;
		justify-content: center;

		&--hide {
			display: none;
		}

		&--error {
			border: 1px solid $c-red;
		}

		&--dragover {
			border: 2px dashed $c-green;
		}

		&__display {
			width: 100%;

			img {
				width: 100%;
			}
		}

		&__remove-button {
			width: 25px;
			height: 25px;
			padding: 0;
			margin: 5px auto 0 0;
			border: 0;
			background-color: transparent;
			color: $c-secondary;
			cursor: pointer;

			img {
				width: 100%;
			}
		}

		&__placeholder {
			display: flex;
			align-items: center;
			justify-content: center;
			color: $c-grey;
			font-size: $text-size-s;
		}

		&__button {
			color: $c-secondary;
			cursor: pointer;
			white-space: pre;
		}

		&__icon {
			display: flex;
		}

		&__file {
			margin: 0 0 20px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__files {
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		&__file-content {
			flex: 1;
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		&__progress {
			width: 0;
			margin-left: 10px;
			height: 3px;
			border-radius: 20px;
			background-color: $c-green;
			align-self: flex-start;
		}

		&__expand-image,
		&__download {
			width: 15px;
			height: 15px;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			img {
				width: 100%;
			}
		}

		&__name {
			margin: 0 10px;
			color: $c-grey;
			font-size: $text-size-s;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&__local-file {
			margin: 0 10px;
			font-size: $text-size-s;
			color: $c-green;
		}

		&__file-size {
			margin: 0 0 0 auto;
			white-space: nowrap;
			color: $c-light-grey;
			font-size: $text-size-s;
		}

		&__remove-icon {
			display: flex;
			cursor: pointer;
		}
	}

	.MuiFormHelperText-root {
		width: 100%;
		margin: 3px 0 0;
		color: $c-red;
		word-break: break-all;
		font-size: $text-size-xs;
		font-weight: $text-weight-normal;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.app-input-drag-and-drop-files-image-modal {
	display: flex;
	align-items: center;

	.input-drag-and-drop-files-image-modal {
		width: 100%;
		max-width: 400px;
		margin: auto;
		padding: 15px;
		border-radius: 8px;
		background-color: $c-white;

		img {
			width: 100%;
		}
	}
}
