.app-mobile-input {
	position: relative;
	width: 100%;
	min-width: 150px;
	margin: 0 0 16px 0;
	display: flex;

	&--disabled {
		.MuiFormControl-root {
			//
			.MuiInputBase-custom-select-input {
				border: 1px solid $c-light-grey !important;
				background-color: #f8f8f8;
			}

			.MuiInputBase-custom-textField-input {
				border: solid 1px $c-light-grey !important;
				background-color: #f8f8f8;
			}
		}
	}

	&__label {
		padding: 0 0 0 14px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
		background-color: $c-white;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__icon {
		position: relative;
		width: 20px;
		height: 20px;
	}

	&__selectable {
		margin: 0 8px 0 0;
	}

	.MuiFormControl-root {
		width: 100%;

		.MuiInputBase-root {
			padding: 0;
			cursor: default;

			&.Mui-error {
				//
				.MuiInputBase-custom-select-input,
				.MuiInputBase-custom-textField-input {
					border: 1px solid $c-red;
				}
			}
		}

		.MuiInputBase-custom-select-input {
			min-width: 48px;
			padding: 0 10px 0 0;
			border-radius: 4px;
			border: 1px solid $c-grey;

			.MuiInputBase-input {
				border: 0;
				padding-right: 0;
			}
		}

		.MuiInputBase-custom-textField-input {
			border-radius: 4px;
			border: 1px solid $c-grey;
		}

		.MuiSelect-icon {
			top: inherit;
			right: 0;
		}

		.MuiInputBase-input {
			padding: 10px 16px 10px 16px;

			&::placeholder {
				color: $c-light-grey;
			}
		}

		.MuiOutlinedInput-notchedOutline {
			border: 0 !important;
		}

		.MuiFormHelperText-root {
			width: 100%;
			margin: 3px 0 0;
			color: $c-red;
			word-break: break-all;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

.app-mobile-input-modal {
	//
	.MuiPaper-root {
		max-height: 300px;

		.MuiFormControl-root {
			width: 100%;

			.MuiInputBase-root {
				padding: 0;

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}

			.MuiInputBase-input {
				padding: 10px 0;
			}

			.MuiOutlinedInput-notchedOutline {
				border: 0 !important;
			}
		}
	}
}
