.page-view-claims {
	//
	.view-claims {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		&__id {
			margin: 0 0 10px;
			font-size: $text-size-l;
		}

		&__header {
			display: flex;
			flex-direction: column;
		}

		&__add-button {
			//
			.app-button {
				max-width: 150px;
				padding: 10px 20px;
				flex-direction: row-reverse;
			}
		}
	}
}
