.app-tabs {
	margin-bottom: 15px;

	.tabs {
		//
		&__header {
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: row;
			margin-bottom: 16px;

			&:after {
				position: absolute;
				left: 0;
				right: 0;
				bottom: 1px;
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				border-radius: 5px;
				background-color: $c-light-grey;
			}
		}

		&__body {
			margin-top: 20px;
		}

		.tab {
			padding: 10px 15px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;

			&__label {
				margin: 0 10px;
				color: $c-light-grey;
				font-size: $text-size-s;
			}

			&__icon {
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&__number {
				width: 25px;
				height: 25px;
				border-radius: 25px;
				color: $c-white;
				font-size: $text-size-s;
				font-weight: $text-weight-semibold;
				background-color: $c-light-grey;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			&--active {
				position: relative;
				z-index: 99;

				&:after {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					content: "";
					display: block;
					width: 100%;
					height: 3px;
					border-radius: 3px;
					background-color: $c-tertiary;
				}

				.tab__label {
					color: $c-tertiary;
					font-weight: $text-weight-semibold;
				}

				.tab__number {
					background-color: $c-tertiary;
				}
			}

			@include max-width($scr-sm) {
				display: none;
			}
		}

		&__select-input {
			//
			.MuiFormControl-root {
				//
				.MuiOutlinedInput-notchedOutline {
					border-radius: 8px;
					border: 1px solid $c-primary;
				}

				.MuiOutlinedInput-input {
					color: $c-primary;
				}

				.MuiSelect-icon {
					color: $c-primary;

					path {
						stroke: $c-primary;
					}
				}
			}

			@include min-width($scr-sm) {
				display: none;
			}
		}
	}
}
