.app-work-completion-report-details-asset-table {
	//
	.work-completion-report-details-asset-table {
		//
		&__header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__search-input {
			width: 100%;
			max-width: 550px;
			display: flex;
			flex-direction: row;

			.app-input {
				margin: 0;
				border-radius: 5px;
				background-color: $c-white;

				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-border;
				}
			}
		}

		&__create-button {
			//
			.app-button {
				width: auto;
				padding: 10px 15px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}
