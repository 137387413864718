.app-quotation-add-item-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.quotation-add-item-modal {
		width: 100%;
		max-height: 90%;
		max-width: 460px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
		}

		&__form {
			width: 100%;
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__row {
			display: flex;
			flex-direction: column;

			@include min-width($scr-xs) {
				flex-direction: row;
				justify-content: space-between;

				.app-input {
					width: 48%;
				}
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px auto 0;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
