.app-positions-table {
	//
	.positions-table {
		//
		.app-table {
			//
			.table {
				margin: 15px 0;

				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}
