.app-edit-user-information {
	//
	.edit-user-information {
		//
		&__container {
			padding: 40px;
			background: $c-white;
			display: flex;
			flex-direction: column;
			border-bottom: solid 1px $c-light-grey;

			&--no-border {
				border: 0;
			}
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__sub-label {
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__row {
			display: flex;
			justify-content: space-between;

			.app-input,
			.app-select-input,
			.app-mobile-input,
			.app-input-multi-select {
				width: calc(50% - 20px);
			}

			.app-mobile-input {
				margin: 0 0 25px 0;
			}

			@include max-width($scr-md) {
				flex-direction: column;

				.app-input,
				.app-select-input,
				.app-mobile-input,
				.app-input-multi-select {
					width: 100%;
				}
			}
		}

		&__column {
			width: calc(50% - 20px);
			display: flex;
			flex-direction: column;

			@include max-width($scr-md) {
				width: 100%;
			}
		}

		&__checkboxes {
			display: flex;
			flex-direction: row;

			.app-checkbox {
				margin: 10px 0;

				& ~ .app-checkbox {
					margin-left: 10px;
				}
			}

			@include max-width($scr-md) {
				flex-direction: column;

				.app-checkbox {
					//
					& ~ .app-checkbox {
						margin-left: 0;
					}
				}
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
