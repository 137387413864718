.app-team-pic-create-edit-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.team-pic-create-edit-modal {
		width: 100%;
		max-width: $scr-w-md;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__search-input {
			width: 100%;
			max-width: 550px;
			margin: 20px 0;
			display: flex;
			flex-direction: row;

			.app-input {
				margin: 0;
				border-radius: 5px;
				background-color: $c-white;

				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-border;
				}
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
