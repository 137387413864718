.page-link-expired {
	min-height: 100vh;
	padding: 10px;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url("../../assets/images/background.webp");
	display: flex;
	align-items: center;
	justify-content: center;

	.link-expired {
		width: 100%;
		max-width: 460px;
		padding: 45px;
		border-radius: 8px;
		background-color: $c-white;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		@include max-width($scr-sm) {
			padding: 35px 25px;
		}

		&__container {
			width: 100%;
			max-width: 320px;
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		&__logo {
			width: 125px;
			display: block;

			img {
				width: 100%;
			}
		}

		&__title {
			margin: 15px 0 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 10px 0 20px;
			color: $c-black;
			text-align: center;
			font-size: $text-size-s;
		}

		&__hero {
			width: 100%;
			max-width: 250px;
			margin: 0 0 15px;

			img {
				width: 100%;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 280px;
		}
	}
}
