.page-invoices {
	//
	.invoices {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		&__header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		}

		&__search-input {
			width: 100%;
			max-width: 550px;
			display: flex;
			flex-direction: row;

			.app-input {
				margin: 0;
				border-radius: 5px;
				background-color: $c-white;

				.MuiOutlinedInput-notchedOutline {
					border: 0;
				}
			}
		}

		&__filter-button {
			margin: 0 10px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;

				&__label {
					color: $c-secondary;
				}
			}
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				&__price {
					text-align: center;

					span {
						justify-content: flex-end;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.invoices-filter-menu {
	width: 100%;
	min-width: 550px;
	display: flex;
	flex-direction: row;
	gap: 20px;

	@include max-width($scr-xs) {
		min-width: 100%;
		flex-direction: column;
	}

	&__container {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&__row {
		display: flex;
		gap: 20px;

		.app-input,
		.app-input-multi-select {
			min-width: 250px;
			margin-top: 15px;
		}
	}

	&__label {
		margin: 0;
		padding: 0 0 0 10px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__grip {
		display: grid;
		grid-template-columns: auto auto auto;
	}

	.app-checkbox {
		padding: 0 10px;
		margin-bottom: 10px;
	}
}
