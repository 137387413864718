.app-customer-create-edit-contract-table {
	//
	.customer-create-edit-contract-table {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				&__text {
					color: $c-light-grey;
					font-size: $text-size-s;
				}

				&__link {
					margin-left: 5px;
					color: $c-secondary;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
					cursor: pointer;
				}
			}
		}
	}
}
