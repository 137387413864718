.app-all-pending-claim-paid-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.all-pending-claim-paid-modal {
		width: 100%;
		max-height: 90%;
		max-width: 600px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 0 0 20px;
			text-align: center;

			span {
				color: $c-secondary;
				font-size: $text-size-m;
			}
		}

		&__footer-button {
			display: flex;
			justify-content: center;
			gap: 20px;
		}

		&__confirm-button {
			//
			.app-button {
				background-color: $c-primary;

				&__label {
					color: $c-white;
				}
			}
		}

		&__cancel-button {
			//
			.app-button {
				border: none;
			}
		}
	}
}
