.app-family-info {
	width: 100%;
	max-width: $scr-w-md;

	.family-info {
		//
		&__container {
			padding: 25px 0;
			margin-bottom: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__header {
			width: 100%;
			padding: 0 25px 25px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.family-info {
				//
				&__label {
					margin: 0;
				}
			}

			.app-button {
				width: auto;
				padding: 10px 20px;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__label {
			margin: 0 25px 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
			display: flex;
			align-items: center;
		}

		&__delete {
			margin: 0 0 0 10px;
			color: $c-red;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			cursor: pointer;
			display: flex;
			align-items: center;

			img {
				width: 25px;
				height: 25px;
			}
		}

		&__row {
			padding: 0 25px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-input,
			.app-select-input,
			.app-input-date {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-select-input,
				.app-input-date {
					width: 100%;
				}
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
