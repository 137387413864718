.page-inventory-create-edit {
	width: 100%;
	max-width: $scr-w-md;

	.inventory-create-edit {
		//
		&__header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&--column {
				flex-direction: column;
				align-items: flex-end;
			}
		}

		&__title {
			width: 100%;
			color: $c-black;
			font-size: $text-size-xl;
		}

		&__last-update {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__transfer-button {
			//
			.app-button {
				height: 45px;
				border: 1px solid $c-secondary;
				background-color: transparent;
				display: flex;
				flex-direction: row-reverse;

				&:disabled {
					background-color: $c-disabled;
					border: none;
				}

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__container {
			padding: 25px;
			margin-bottom: 20px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);

			&--divider {
				margin-bottom: 0;
				border-bottom: 1px solid $c-border;
				flex-wrap: wrap;
			}
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__image {
			max-width: 200px;

			img {
				width: 100%;
				margin-bottom: 20px;
			}
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-input,
			.app-select-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-select-input {
					width: 100%;
				}
			}
		}

		&__column {
			width: calc(50% - 20px);
			display: flex;
			flex-direction: column;

			.app-input,
			.app-select-input {
				width: 100%;
			}

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__threshold-buttons {
			margin: 20px 0 25px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.app-button {
				width: calc(50% - 5px);
				height: 45px;
				border: 1px solid $c-secondary;
				background-color: transparent;
				display: flex;
				flex-direction: row-reverse;

				&:disabled {
					background-color: $c-disabled;
					border: none;
				}

				&__label {
					color: $c-secondary;
				}
			}

			@include max-width($scr-xs) {
				width: 100%;
				margin: 0;

				.app-button {
					margin-bottom: 20px;
				}
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
