.page-breakdown-work-order {
	//
	.breakdown-work-order {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		&__header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 10px;

			.app-table-filter-header {
				width: 100%;
			}
		}

		&__create-button {
			//
			.app-button {
				padding: 8px 20px;
				flex-direction: row-reverse;
			}
		}

		&__header-button {
			//
			.app-button {
				width: 190px;
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		.app-table {
			margin: 15px 0;

			&__cell {
				border-bottom: 0 !important;

				.app-checkbox {
					margin-left: auto;
					margin-right: auto;
				}

				&--header {
					background-color: $c-header-blue;

					.app-checkbox__box {
						//
						svg {
							//
							path {
								stroke: $c-header-blue;
							}
						}
					}
				}
			}

			.table {
				//
				&__edit {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.breakdown-work-order-filter {
	width: 100%;
	max-width: 600px;
	display: flex;
	gap: 20px;
	flex-direction: column;

	@include max-width($scr-w-xs) {
		width: calc(100% - 32px);
	}

	&__container {
		display: flex;
		flex-direction: column;
	}

	&__inputs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.app-input-date {
			width: calc(50% - 2px);
			margin: 0 0 5px;

			& ~ .app-input-date {
				align-items: flex-end;
			}
		}

		@include max-width($scr-w-xs) {
			flex-direction: column;
			align-items: flex-start;

			.app-input-date {
				width: 100%;
			}
		}
	}

	&__instruction {
		margin: 0;
		color: $c-light-grey;
		font-size: $text-size-s;

		&--error {
			color: $c-red;
		}
	}

	&__label {
		margin: 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__checkbox-status {
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		@include max-width($scr-w-xs) {
			display: flex;
			flex-direction: column;
		}

		.app-checkbox {
			margin: 10px 10px 5px 0;
		}
	}
}
