.app-scheduled-attendance-employee-calendar {
	width: 100%;

	.scheduled-attendance-employee-calendar {
		//
		.app-input-time {
			max-width: 200px;
		}

		.calendar-schedule {
			//
			&__table {
				//
				.MuiPaper-root {
					box-shadow: 0px 0px 10.7px 0px rgba(163, 166, 172, 0.2);

					div {
						margin: auto;
					}

					.MuiTableCell-head {
						//
						&:nth-child(2),
						&:nth-child(3) {
							text-align: center;
						}
					}

					.MuiTableCell-body {
						//
						&:nth-child(2),
						&:nth-child(3) {
							text-align: center;
						}
					}
				}
			}
		}
	}
}
