.page-inventory {
	//
	.inventory {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}

				.MuiTableCell-body {
					//
					&:nth-child(7),
					&:nth-child(8) {
						text-align: right;
					}
				}
			}
		}
	}
}

.inventory-table-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}

	&__close-icon {
		transform: rotate(45deg);
	}
}

.inventory-filter {
	width: 100%;
	min-width: 500px;
	display: flex;
	gap: 20px;
	flex-direction: row;

	@include max-width($scr-xs) {
		min-width: 100%;
		flex-direction: column;
	}

	&__container {
		gap: 8px;
		display: flex;
		flex-direction: column;
	}

	&__label {
		margin: 0;
		padding: 0 0 5px 0;
		color: $c-dark-grey;
		font-size: 14px;
		font-weight: 600;
	}
}
