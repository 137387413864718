.app-header {
	width: 100%;
	height: $header-height;
	padding: 0 80px 0 0;
	background-color: $c-deep-blue;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-shrink: 0;

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&__menu {
			width: 50px;
			height: 50px;
			margin: 0 0 0 10px;
			border: 0;
			background-color: $c-deep-blue;
			background-position: center;
			background-repeat: no-repeat;
			cursor: pointer;
			flex-shrink: 0;

			@include max-width($scr-sm) {
				display: block;
			}

			&--shrinked {
				.header__side-icon {
					transform: scaleX(-1);
					transition: $transition;
				}
			}
		}

		&__side-icon {
			transform: scaleX(1);
			transition: $transition;
		}

		&__back {
			border: 0;
			padding: 0;
			cursor: pointer;
			background-color: transparent;
			display: flex;
			align-items: center;

			@include max-width($scr-sm) {
				margin-left: 15px;
				margin-right: auto;
			}
		}

		&__profile {
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		&__user {
			display: flex;
			flex-direction: column;
			height: 40px;
			align-items: center;
			margin: 0 20px 0 30px;
		}

		&__user-details {
			display: flex;
			align-items: center;
			flex-direction: row;
			cursor: pointer;
		}

		&__name {
			margin: 0;
			color: $c-white;
		}

		&__text {
			margin: 0;
			color: $c-yellow;
			font-size: $text-size-xs;
		}

		&__image {
			margin: 0 20px 0 0;
			width: 35px;
			height: 35px;
			border-radius: 100px;
			overflow: hidden;
			background-size: cover;
		}

		&__dropdown {
			position: relative;
			max-height: 0;
			margin: 10px 0 0;
			border-radius: 5px;
			background: $c-white;
			box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
			transition: $transition;
			overflow: hidden;
			z-index: 1;
			display: flex;
			align-items: flex-end;
			flex-direction: column;
			flex-shrink: 0;

			&--active {
				max-height: 108px;
			}
		}

		&__dropdown-option {
			width: 100%;
			padding: 15px 20px;
			display: flex;
			flex-direction: row;
			cursor: pointer;

			.app-icon {
				margin-left: 15px;
			}
		}

		&__description {
			margin: 0 auto 0 0;
		}

		&__notification {
			position: relative;
			width: 20px;
			height: 20px;
			cursor: pointer;

			img {
				width: 100%;
			}
		}
	}
}
