.app-input-create-edit-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.input-create-edit-modal {
		width: 100%;
		max-width: 460px;
		max-height: 90%;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__label {
			margin: 0 0 16px;
			color: $c-deep-blue;
			font-weight: $text-weight-bold;
		}

		&__form {
			width: 100%;
			min-height: 100%;
			display: flex;
			flex-direction: column;
		}

		&__wrapper {
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__options {
			padding: 10px 0;
			overflow-y: auto;
		}

		&__option {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-select-input {
				width: 50px;
				min-width: 50px;
				margin: 0 10px 0 0;

				.MuiInputBase-root {
					padding: 0 8px;
				}

				.MuiOutlinedInput-input {
					padding: 10px 4px;
					text-align: center;
				}
			}
		}

		&__delete {
			margin-top: 8px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			align-self: flex-start;

			img {
				height: 100%;
			}

			&--first {
				margin-top: 0;
				align-self: center;
			}

			&--disabled {
				cursor: not-allowed;
				filter: grayscale(1);
				opacity: 0.5;
			}
		}

		&__add {
			max-width: 100px;
			margin: 15px 0;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				display: flex;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}
			}

			&--disabled {
				//
				.app-button {
					border: 1px solid $c-disabled;

					&__label {
						color: $c-disabled;
					}
				}
			}
		}

		&__button-containers {
			max-width: 320px;
			margin: 20px auto 0;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
