.app-attendance-summary-calendar {
	width: 100%;

	.attendance-summary-calendar {
		//
		.app-status {
			//
			.status {
				//
				&__text {
					font-size: $text-size-s;
				}
			}
		}

		.calendar-schedule {
			//
			&__table {
				//
				.MuiPaper-root {
					box-shadow: 0px 0px 10.7px 0px rgba(163, 166, 172, 0.2);

					.MuiTableCell-head {
						//
						&:nth-child(2) {
							text-align: end;
						}

						&:nth-child(3) {
							text-align: center;
						}
					}

					.MuiTableCell-body {
						//
						&:nth-child(2) {
							text-align: end;
						}

						&:nth-child(3) {
							text-align: center;
						}
					}
				}
			}
		}
	}
}
