.app-modal-alert {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999999999999;
	opacity: 0;
	display: none;
	transition: $transition;

	&--active {
		display: block;
		animation: overlayFadeIn 1s forwards;

		//
		.modal-alert {
			//
			&__card {
				animation: cardFadeIn 1s forwards;
			}
		}
	}

	&--inactive {
		display: block;
		animation: overlayFadeOut 1s forwards;

		//
		.modal-alert {
			//
			&__card {
				animation: cardFadeOut 1s forwards;
			}
		}
	}

	.modal-alert {
		width: 100%;
		height: 100%;
		padding: 15px;
		display: flex;
		align-items: center;
		justify-content: center;

		&__card {
			width: 100%;
			max-width: 640px;
			padding: 25px;
			margin: 0 auto;
			border-radius: 10px;
			background-color: $c-white;
			box-shadow: 0px 2px 16px 0px rgba(182, 189, 194, 0.15);
			transform: translateY(-100%);
			transition: $transition;
			pointer-events: auto;
			opacity: 0;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 25px 0;
			text-align: center;
			color: $c-dark-grey;
			font-size: $text-size-s;
			white-space: pre;
		}

		&__button-container {
			width: 100%;
			max-width: 320px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}

@keyframes overlayFadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes overlayFadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes cardFadeIn {
	from {
		opacity: 0;
		transform: translateY(-100%);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes cardFadeOut {
	from {
		opacity: 1;
		transform: translateY(0);
	}
	to {
		opacity: 0;
		transform: translateY(-100%);
	}
}
