.page-customer-configuration {
	//
	.customer-configuration {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}
	}
}
