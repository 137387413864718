.app-toggle-switch {
	margin: 0;

	.MuiSwitch-root {
		height: 45px;
		padding: 10px 7px;
		align-items: center;
	}

	.MuiSwitch-track {
		border-radius: 100px;
		background-color: $c-light-grey;
	}

	.MuiSwitch-thumb {
		width: 15px;
		height: 15px;
		color: $c-white;
	}

	.MuiSwitch-switchBase {
		top: inherit;
		padding: 10px;

		&.Mui-checked {
			color: $c-white;
			transform: translateX(22px);

			.MuiSwitch-thumb {
				background-size: 10px;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url("../../assets/images/blue-tick-icon.svg");
			}

			+ .MuiSwitch-track {
				border: 0;
				opacity: 1;
				background-color: #0369c5;
			}
		}
	}
}
