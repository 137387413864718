.page-customer-create-edit-contract {
	width: 100%;
	max-width: $scr-w-md;

	.customer-create-edit-contract {
		//
		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-button {
				display: flex;
				flex-direction: row-reverse;

				.app-button {
					width: auto;
					padding: 8px 15px;
					background-color: transparent;
					border: 1px solid $c-secondary;
					flex-direction: row-reverse;

					&__label {
						color: $c-secondary;
						white-space: nowrap;
					}

					.app-icon {
						width: 20px;
						height: 20px;
					}
				}
			}
		}

		&__clone {
			display: flex;
			flex-direction: column;

			.app-button {
				width: 40%;
				min-width: 125px;
				padding: 10px 15px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				align-self: flex-end;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__last-update {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__title {
			color: $c-black;
			font-size: $text-size-xl;
		}

		&__container {
			padding: 25px 0;
			margin-bottom: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__label {
			margin: 0 25px 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__instructions {
			font-size: $text-size-body;
			color: $c-grey;
		}

		&__row {
			padding: 0 25px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&--divider {
				margin-bottom: 25px;
				border-bottom: 1px solid $c-border;
				flex-wrap: wrap;
			}

			.app-input,
			.app-select-input,
			.app-input-date {
				width: calc(50% - 20px);
			}

			.app-input--multiline {
				width: 100%;
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input {
					width: 100%;
				}
			}
		}

		&__table,
		&__documents {
			padding: 0 25px;
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
