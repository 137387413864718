.app-work-order {
	//
	.work-order {
		//
		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__container {
			margin-bottom: 20px;
			padding: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}
	}
}
