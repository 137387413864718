.app-inventory-history-movement-view-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.inventory-history-movement-view-modal {
		width: 100%;
		max-height: 90%;
		max-width: 600px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}
	}
}
