.app-hr-view-holidays-delete-modal {
	min-width: 300px;
	padding: 10px;
	display: flex;

	.hr-view-holidays-delete-modal {
		width: 100%;
		max-width: 560px;
		margin: auto;
		padding: 50px;
		border-radius: 8px;
		background-color: $c-white;

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
			text-align: center;
		}

		&__description {
			color: $c-dark-grey;
			text-align: center;

			&--red {
				color: $c-red;
			}

			&--bold {
				font-weight: $text-weight-semibold;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px auto 0;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
