.page-notifications-create-edit {
	width: 100%;
	max-width: $scr-w-md;

	.notifications-create-edit {
		//
		&__title {
			color: $c-black;
			font-size: $text-size-xl;
		}

		&__container {
			padding: 25px 0;
			margin-bottom: 15px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__row {
			padding: 0 25px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&--divider {
				padding: 0;
				margin-bottom: 25px;
				border-bottom: 1px solid $c-border;
			}

			&--footer {
				flex-wrap: wrap;
			}

			.app-input,
			.app-input-date,
			.app-input-time {
				width: calc(50% - 20px);
			}

			.app-input {
				//
				&--multiline {
					width: 100%;
				}
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-input-date,
				.app-input-time,
				.app-input-select-multiple {
					width: 100%;
				}
			}
		}

		&__push-notification {
			padding-top: 20px;
			min-width: 250px;
			margin-top: auto;
			text-align: left;
			white-space: nowrap;
			justify-content: flex-start;
		}

		&__clear-notification {
			height: 24px;
			margin: -16px 0 8px 0;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
			color: $c-red;
			display: flex;
			justify-content: right;
			align-items: center;
		}

		&__delete-button {
			margin-left: auto;
			cursor: pointer;
			border: 0;
			background-color: transparent;
			transition: $transition;
			display: flex;
			align-items: center;

			&:hover {
				filter: brightness(150%);
			}

			.app-icon {
				height: 16px;
				width: 16px;
			}
		}

		&__delete-notification {
			margin: 0 0 15px auto;
			display: flex;
			align-items: center;

			.notifications-create-edit__label {
				color: $c-red;
			}
		}

		&__clear-button {
			display: flex;
			cursor: pointer;
			border: 0;
			background-color: transparent;
			transition: $transition;

			&:hover {
				filter: brightness(150%);
			}
		}

		&__label {
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
		}

		&__buttons-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}

		&__clone-button {
			display: flex;
			flex-direction: row-reverse;

			.app-icon {
				width: 18px;
				height: 18px;
			}
		}
	}
}
