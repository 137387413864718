.app-pending-corrective-attendance-employee-table {
	//
	.pending-corrective-attendance-employee-table {
		//
		&__header {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			gap: 10px;
		}

		&__header-button {
			display: flex;

			.app-button {
				width: 100%;
				justify-content: center;
			}
		}

		&__approve-button {
			//
			.app-button {
				padding: 8px 20px;
				flex-direction: row-reverse;
				background-color: $c-green;
				border: 1px solid $c-green;
			}
		}

		&__reject-button {
			margin: 0 10px 0 0;

			.app-button {
				padding: 8px 20px;
				border: 1px solid $c-red;
				flex-direction: row-reverse;

				&__label {
					color: $c-red;
				}
			}
		}

		.app-table {
			margin: 15px 0;

			&__cell {
				border-bottom: 0 !important;

				.app-checkbox {
					margin-left: auto;
					margin-right: auto;
				}

				&--header {
					background-color: $c-header-blue;

					.app-checkbox__box {
						//
						svg {
							//
							path {
								stroke: $c-header-blue;
							}
						}
					}
				}
			}

			.table {
				//
				&__view {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}

				.MuiTableCell-body {
					//
					&:nth-child(3) {
						border-left: 1px solid $c-header-blue;
						background-color: $c-background;
					}

					&:nth-child(5),
					&:nth-child(8) {
						border-right: 1px solid $c-header-blue;
						background-color: $c-background;
					}

					&:nth-child(4),
					&:nth-child(6),
					&:nth-child(7) {
						background-color: $c-background;
					}
				}
			}
		}
	}
}
