.app-customer-edit-asset-add-service-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.customer-edit-asset-add-service-modal {
		width: 100%;
		max-height: 90%;
		max-width: 1024px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-l;
		}

		&__form {
			width: 100%;
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			font-size: $text-size-s;

			span {
				color: $c-green;
			}
		}

		&__filter-button {
			margin: 0 10px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}

		.table-filter-header {
			margin: 20px 0;
			//
			.app-input {
				//
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-border;
				}
			}
		}
	}
}

.customer-edit-asset-add-service-modal-filter {
	width: 100%;
	display: flex;
	gap: 8px;
	flex-direction: column;

	&__label {
		margin: 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}
}
