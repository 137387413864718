.app-notifications-file-upload {
	//
	.file-upload {
		width: 100%;

		&__title {
			margin-bottom: 10px;
			color: $c-deep-blue;
			font-size: $text-size-body;
			font-weight: $text-weight-bold;
		}

		&__description {
			margin-bottom: 16px;
			color: $c-grey;
			font-size: $text-size-s;
		}

		&__file {
			display: flex;
			align-items: center;
		}

		&__file-wrapper {
			width: 100%;
			padding: 10px 5px 10px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__file-content {
			position: relative;
			width: 100%;
			display: flex;
			align-items: center;
		}

		&__progress {
			position: absolute;
			width: 0;
			bottom: -10px;
			height: 3px;
			border-radius: 20px;
			background-color: $c-green;
		}

		&__icon {
			width: 25px;
			height: 25px;
			flex-shrink: 0;

			img {
				width: 100%;
			}
		}

		&__expand-image {
			width: 18px;
			height: 18px;
			cursor: pointer;
			display: flex;
			align-items: center;
			flex-shrink: 0;

			img {
				width: 100%;
			}
		}

		&__name {
			margin: 0 10px;
			color: $c-grey;
			font-size: $text-size-s;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&__size {
			margin: 0 0 0 auto;
			padding: 0 25px 0 15px;
			white-space: nowrap;
			color: $c-light-grey;
			font-size: $text-size-s;
		}

		&__remove-icon {
			display: flex;
			cursor: pointer;
		}

		&__error {
			margin: 4px 0 0 0;
			color: $c-red;
			font-size: $text-size-xs;
		}

		.app-button {
			max-width: 150px;
			padding: 10px 15px;
			background-color: transparent !important;
			border: 1px solid $c-secondary;

			&__label {
				color: $c-secondary;
			}
		}
	}
}

.app-notification-file-image-modal {
	display: flex;
	align-items: center;

	.notification-file-image-modal {
		width: 100%;
		max-width: 400px;
		margin: auto;
		padding: 15px;
		border-radius: 8px;
		background-color: $c-white;

		img {
			width: 100%;
		}
	}
}
