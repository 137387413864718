.app-reorder-service-lines-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.reorder-service-lines-modal {
		width: 100%;
		max-height: 90%;
		max-width: 1200px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		overflow: scroll;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-l;
		}

		.app-select-input {
			margin: 0;
			width: 50px;
			min-width: 50px;

			.MuiInputBase-root {
				padding: 0 8px;
			}

			.MuiOutlinedInput-input {
				padding: 10px 4px;
				text-align: center;
			}

			.MuiOutlinedInput-notchedOutline {
				border: none;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
