.app-user-information {
	//
	.user-information {
		//
		&__container {
			padding: 40px;
			background: $c-white;
			display: flex;
			flex-direction: column;

			&--border {
				border-top: solid 1px $c-light-grey;
			}
		}

		&__label {
			margin: 0 0 5px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-bold;
		}

		&__description {
			margin: 0 0 20px;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__column {
			width: calc(50% - 20px);
			
			.app-input-drag-and-drop {
				width: 100%;
			}
		}

		&__row {
			display: flex;
			justify-content: space-between;

			&--signature {
				//
				@include max-width($scr-md) {
					flex-direction: column;
				}
			}

			.app-input,
			.app-mobile-input {
				width: calc(50% - 20px);
			}

			.app-mobile-input {
				margin: 0 0 25px 0;
			}

			.app-input-drag-and-drop {
				margin-bottom: 10px;
			}

			@include max-width($scr-md) {
				flex-direction: column;

				.app-input,
				.app-mobile-input {
					width: 100%;
				}
			}
		}
	}
}
