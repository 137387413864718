.app-inventory-history-movement-table {
	//
	.inventory-history-movement-table {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				&__view {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.inventory-history-movement-table-filter {
	width: 100%;
	min-width: 500px;
	display: flex;
	flex-direction: row;
	gap: 20px;

	@include max-width($scr-xs) {
		min-width: 100%;
		flex-direction: column;
	}

	&__container {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&__label {
		margin: 0;
		padding: 0 0 5px 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}
}
