.page-remuneration-packages {
	//
	.remuneration-packages {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}
	}
}
