.app-sales-order-invoice {
	//
	.sales-order-invoice {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				&__text {
					color: $c-light-grey;
					font-size: $text-size-s;
				}

				&__link {
					margin-left: 5px;
					color: $c-secondary;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
					cursor: pointer;
				}
			}
		}
	}
}
