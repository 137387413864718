.app-user-alert-configuration {
	//
	.user-alert-configuration {
		display: flex;
		flex-direction: column;

		&__body {
			padding: 25px;
			background-color: $c-white;
			display: flex;
			flex-direction: column;
		}

		&__header {
			color: $c-black;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__label {
			padding: 0 20px;
			font-weight: $text-weight-bold;
		}

		&__actions {
			width: 50%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__action {
			width: 50%;
			text-align: center;
			font-weight: $text-weight-bold;
		}

		&__rules {
			display: flex;
			flex-direction: column;
		}

		&__box {
			padding: 20px 0 20px 20px;
			margin: 0 0 10px;
			border-radius: 8px;
			background-color: #f4f8fc;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		&__rule {
			max-width: 550px;

			@include max-width($scr-md) {
				padding: 15px;
			}
		}

		&__title {
			margin: 0 0 5px;
			color: $c-deep-blue;
		}

		&__description {
			margin: 0;
			color: $c-grey;
			font-size: $text-size-s;
		}

		&__switches {
			width: 50%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-shrink: 0;
		}

		&__switch {
			width: 50%;
			text-align: center;

			.MuiSwitch-thumb {
				color: $c-white;
			}
		}
	}
}
