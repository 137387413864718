.app-convert-work-order-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.convert-work-order-modal {
		width: 100%;
		max-width: 1024px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-l;
		}

		&__description {
			margin: 14px 0;
			color: $c-dark-grey;
			font-size: $text-size-s;

			span {
				font-weight: $text-weight-semibold;
				color: $c-deep-blue;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}

			&--second-step {
				margin: 30px auto 0 auto;
			}
		}

		.app-select-input {
			margin: 0;
		}

		&--second-step {
			width: auto;
		}
	}
}
