.app-customer-asset-list-failed-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.customer-asset-list-failed-modal {
		width: 100%;
		max-height: 90%;
		max-width: 460px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__success-list {
			width: 100%;
			height: 100%;
			max-height: 200px;
			overflow: scroll;
		}

		&__file-name {
			color: $c-primary;
			font-weight: $text-weight-normal;
		}

		&__description {
			margin: 0 0 15px;
			color: $c-dark-grey;
			text-align: center;

			span {
				color: $c-red;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 160px;
			margin: 30px auto 0;
			display: flex;
			flex-direction: row;
		}
	}
}
