.app-edit-service-listing-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.edit-service-listing-modal {
		width: 100%;
		max-height: 90%;
		max-width: $scr-w-sm;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			font-weight: bold;
			text-align: center;
			font-size: $text-size-l;
		}

		&__header {
			margin: 0;
			font-weight: bold;
			color: $c-focused-blue;
			font-size: $text-size-m;
		}

		&__form {
			min-height: 100%;
			display: flex;
			flex-direction: column;
		}

		&__wrapper {
			width: 100%;
			height: 100%;
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			display: flex;
			flex-direction: column;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-input,
			.app-select-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-select-input {
					width: 100%;
				}
			}

			.app-button {
				width: 25%;
				margin: 10px 0 0;
				border: 1px solid $c-secondary;
				background-color: transparent !important;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__add-service-button {
			padding-top: 25px;

			.app-button {
				max-width: 160px;
				margin: 0;
				border: 1px solid $c-secondary;
				background-color: transparent !important;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__option {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-select-input {
				width: 50px;
				max-width: 50px;
				min-width: 50px;
				margin-right: 10px;
			}

			.app-input {
				//
				& ~ .app-input {
					margin-left: 10px;
				}
			}
		}

		&__delete {
			margin-top: 8px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
			align-self: flex-start;

			img {
				height: 100%;
			}

			&--first {
				margin-top: 0;
				align-self: center;
			}

			&--disabled {
				cursor: not-allowed;
				filter: grayscale(1);
				opacity: 0.5;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px auto 0;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
