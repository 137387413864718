.app-calendar-schedule-footer {
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	&__accumulated-time {
		//
		span {
			color: $c-grey;
			font-size: $text-size-s;
		}
	}

	&__time {
		//
		span {
			font-size: $text-size-s;
			color: rgba(192, 195, 202, 1);
		}
	}
}
