.page-home {
	width: 100%;
	max-width: $scr-w-md;

	.home {
		&__greeting {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-xl;
		}

		&__main-header {
			margin: 15px 0;
		}

		&__amount {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__container {
			padding: 25px;
			margin: 10px 0;
			border-radius: 10px;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
			background-color: $c-white;

			&--open-enquiries-invoices {
				width: 100%;
			}

			&--leave-claim {
				height: 50%;

				@include max-width($scr-xs) {
					height: 100%;
				}
			}
		}

		&__wrapper {
			//
			&--main {
				gap: 20px;
				display: flex;

				@include max-width($scr-xs) {
					flex-direction: column;
				}
			}

			&--updates-enquiries-invoices {
				width: calc(70% - 20px);
				display: flex;
				flex-direction: column;

				@include max-width($scr-xs) {
					width: 100%;
					flex-wrap: wrap;
				}
			}

			&--enquiries-invoices {
				gap: 20px;
				display: flex;
				flex-direction: row;

				@include max-width($scr-xs) {
					gap: 0;
					flex-direction: column;
				}
			}

			&--pending-approval {
				width: calc(30% - 10px);
				display: flex;
				flex-direction: column;

				@include max-width($scr-xs) {
					width: 100%;
				}
			}

			&--total {
				display: flex;
				justify-content: space-between;
			}
		}

		&__header {
			display: flex;
			align-items: center;
		}

		&__title {
			color: $c-primary;
			font-size: $text-size-m;
		}

		&__update-title {
			margin-bottom: 0;
			padding: 0 18px 0;
			color: $c-primary;
			font-size: $text-size-m;

			&--alert {
				color: $c-red;
			}
		}

		&__header-button {
			display: flex;
			flex-direction: row;

			.app-button {
				padding: 10px 15px;
				flex-direction: row-reverse;
				background-color: transparent;
			}
		}

		&__footer {
			//
			.app-button {
				width: 60%;
				padding: 0;
				flex-direction: row-reverse;
				background-color: transparent;
			}

			&--mobile-customers {
				//
				.app-button {
					width: 40%;
				}
			}
		}

		&__text {
			margin: 5px;
			font-size: $text-size-s;

			&--in-progress {
				padding: 5px 10px;
				border-radius: 20px;
				border: 1px solid $c-yellow;
				background-color: rgba(255, 255, 0, 0.1);
				color: $c-yellow;
			}

			&--approval {
				margin: 10px 0;
				font-weight: bold;
				color: $c-blue-grey;
			}
		}

		&__value {
			margin: 5px 0;
			padding: 5px 10px;
			border-radius: 20px;
			font-size: $text-size-s;

			&--verify,
			&--completed,
			&--open-enquiries {
				border: 1px solid $c-green;
				background-color: rgba(68, 175, 105, 0.1);
				color: $c-green;
			}

			&--open,
			&--on-hold,
			&--in-progress {
				border: 1px solid $c-yellow;
				background-color: rgba(255, 255, 0, 0.1);
				color: $c-yellow;
			}

			&--scheduled {
				border: 1px solid $c-brown;
				background-color: rgba(230, 142, 10, 0.1);
				color: $c-brown;
			}

			&--overdue {
				border: 1px solid $c-red;
				background-color: rgba(220, 76, 76, 0.1);
				color: $c-red;
			}
		}

		&__total {
			margin: 10px 0;
			color: $c-tertiary;
			font-size: $text-size-xxl;
		}

		&__button {
			border: 0;
			width: 10px;
			margin-right: 10px;
		}

		&__view-button {
			border: 0;

			.app-button {
				&__label {
					color: $c-secondary;
				}
			}

			&--recent-updates {
				//
				.app-button {
					//
					&__label {
						margin-right: 10px;
						text-align: left;
						color: $c-grey;
					}
				}
			}

			&--enquiries-invoices {
				width: 25%;

				@include max-width($scr-xs) {
					width: 100%;
				}
			}
		}

		&__last-update {
			margin: 20px 0;
			color: $c-black;
			font-size: $text-size-s;
		}

		&__row {
			&--work-order {
				padding: 0 23px;
			}

			&--divider {
				border-right: 2px solid $c-border;

				@include max-width($scr-xs) {
					border-right: 0;
					border-bottom: 2px solid $c-border;
				}
			}
		}

		&__column {
			//
			&--work-order {
				display: flex;
				flex-direction: row;

				@include max-width($scr-xs) {
					flex-wrap: wrap;
					flex-direction: column;
				}
			}

			&--divider {
				border-bottom: 2px solid $c-border;
			}

			&--container-divider {
				width: 100%;
				border-bottom: 2px solid $c-blue-grey;

				@include max-width($scr-xs) {
					width: 100%;
				}
			}
		}
	}
}
