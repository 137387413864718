$font-family: Lato, Poppins, Roboto, sans-serif;
$side-nav-width: 272px;
$side-nav-shrinked-width: 60px;
$header-height: 70px;
$transition: 150ms cubic-bezier(0.1, 0.7, 0.6, 0.9);

/* -- Color -- */
$c-white: #ffffff;
$c-black: #000000;
$c-primary: #043763;
$c-secondary: #0ea1fc;
$c-tertiary: #0369c5;
$c-disabled: #a3a6ac;
$c-border: #eaeaea;
$c-red: #dc4c4c;
$c-grey: #4f4f4f;
$c-lighter-grey: #fbfafa;
$c-pale-grey: #f3f6f9;
$c-light-grey: #c0c3ca;
$c-dark-grey: #666666;
$c-footer-grey: #edeff0;
$c-background: #f6fbff;
$c-blue: #0245a9;
$c-light-blue: #f8fdff;
$c-deep-blue: #043763;
$c-focused-blue: #1976d2;
$c-header-blue: #c5eafa;
$c-yellow: #ffb904;
$c-orange: #ffab5e;
$c-green: #44af69;
$c-blue-grey: #7ba1c4;
$c-brown: #e68e0a;

/* -- Font size -- */
$text-size-xxxl: 48px;
$text-size-xxl: 36px;
$text-size-xl: 28px;
$text-size-l: 22px;
$text-size-m: 18px;
$text-size-body: 16px;
$text-size-s: 14px;
$text-size-xs: 12px;
$text-size-xxs: 10px;

/* -- Font weight -- */
$text-weight-strong: 900;
$text-weight-exbold: 800;
$text-weight-bold: 700;
$text-weight-semibold: 600;
$text-weight-normal: 500;
$text-weight-regular: 400;
$text-weight-thin: 300;
$text-weight-light: 200;
$text-weight-exlight: 100;

/* -- Window Size -- */
$scr-w-xlg: 1360px;
$scr-w-lg: 1240px;
$scr-w-md: 1024px;
$scr-w-sm: 768px;
$scr-w-xs: 640px;

/* -- Media query -- */
$scr-xlg: "1240px";
$scr-lg: "1199px";
$scr-md: "1024px";
$scr-sm: "768px";
$scr-xs: "679px";
