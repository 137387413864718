.app-view-employee-leave-summary-table {
	//
	.view-employee-leave-summary-table {
		//
		&__body {
			//
			.app-table {
				margin: 15px 0;

				.table {
					//
					.MuiTableCell-head {
						//
						&:nth-child(2) {
							text-align: end;
						}

						&:nth-child(3) {
							text-align: center;
						}
					}

					.MuiTableCell-body {
						//
						&:first-child {
							max-width: 120px;
							background-color: $c-background;
							border-right: 1px solid $c-header-blue;
						}

						&:nth-child(2) {
							text-align: end;
						}

						&:nth-child(3) {
							text-align: center;
						}
					}
				}
			}
		}
	}
}
