.app-inventory-history-approve-reject-component {
	//
	.inventory-history-approve-reject-component {
		//
		&__text {
			margin: 20px 0;
			color: $c-dark-grey;
			text-align: center;

			&--approved {
				color: $c-green;
			}

			&--rejected {
				color: $c-red;
			}

			&--authorization {
				margin: 20px 0 0;
			}
		}

		&__row {
			width: 100%;
			max-width: 250px;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
		}

		&__description {
			width: 50%;
			margin: 5px 0;
			color: $c-black;
			font-weight: $text-weight-semibold;

			&--title {
				color: $c-dark-grey;
				width: 150%;
			}
		}

		&__button-container {
			width: 100%;
			max-width: 240px;
			margin: 10px auto 0;
			display: flex;
			flex-direction: row;
			gap: 15px;

			.app-button {
				//
				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
