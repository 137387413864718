.app-radio-input {
	position: relative;
	width: 100%;
	min-width: 150px;
	margin-bottom: 25px;
	display: flex;

	&__label {
		padding: 0 0 0 14px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
		background-color: $c-white;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__radios {
		padding: 5px 0 0 14px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
	}

	&__item {
		margin-top: 10px;
		display: flex;
		align-items: flex-start;
		flex-direction: row;
	}

	&__text {
		margin: 0 20px 0 10px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__description {
		margin: 0 0 0 10px;
		color: $c-light-grey;
		font-size: $text-size-xs;

		&--bold {
			margin: 0;
			color: $c-light-grey;
			font-size: $text-size-xs;
			font-weight: $text-weight-semibold;
		}
	}

	&__radio {
		width: 20px;
		height: 20px;
		border-radius: 20px;
		border: 1px solid $c-border;
		background-color: transparent;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;

		&--active {
			&:before {
				content: "";
				width: 10px;
				height: 10px;
				border-radius: 10px;
				background-color: $c-secondary;
				flex-shrink: 0;
			}
		}
	}

	&__disabled {
		cursor: not-allowed;
	}

	.MuiFormControl-root {
		width: 100%;

		.MuiFormHelperText-root {
			width: 100%;
			margin: 3px 0 0;
			color: $c-red;
			word-break: break-all;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
