.app-notifications-recipient-modal {
	display: flex;
	align-items: center;
	justify-content: center;

	.recipient-modal {
		width: 60%;
		height: 826px;
		padding: 8px 24px;
		border-radius: 4px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close-container {
			width: 100%;
			left: 835px;
			margin: 8px 5px 0 0;
			padding: 0;
			position: relative;
			border: none;
			background: none;
			cursor: pointer;
			display: flex;
			justify-content: flex-end;
		}

		&__close-button {
			width: 30px;
			margin: 8px 0 0 0;
			border: none;
			background: none;
			padding: 0;
			cursor: pointer;
		}

		&__search {
			margin: 0 0 8px 0;
		}

		&__chips {
			margin: 0 0 8px 0;
			min-height: 30px;
			overflow: auto;
		}

		&__users {
			display: flex;
			flex-direction: column;
			overflow: auto;
			border-bottom: solid 1px $c-footer-grey;
		}

		&__user {
			display: flex;
			align-items: center;
			height: 36px;

			.app-checkbox {
				&__label {
					color: $c-black;
				}
			}

			&--individual {
				border-top: solid 1px $c-footer-grey;
			}
		}

		@include max-width($scr-sm) {
			min-width: 250px;
		}
	}
}
