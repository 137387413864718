.page404 {
	display: flex;
	flex-direction: column;

	&__title {
		display: flex;
		justify-content: center;
	}

	&__img {
		display: flex;
		justify-content: center;
		.img {
			width: 400px;
			height: 480px;
		}
	}

	&__reroute {
		display: flex;
		flex-direction: row;
		justify-content: center;
		.text {
			text-decoration: none;
		}
	}
}
