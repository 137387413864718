.app-select-input {
	position: relative;
	width: 100%;
	min-width: 150px;
	margin-bottom: 25px;
	display: flex;

	&--disabled {
		//
		.MuiInputBase-root {
			background-color: #f8f8f8;
		}
	}

	&__label {
		padding: 0 0 0 14px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__icon {
		position: relative;
		width: 18px;
		height: 18px;
		display: flex;
		align-items: center;

		svg {
			right: inherit;
		}
	}

	&__cursor {
		cursor: pointer;
	}

	.MuiFormControl-root {
		width: 100%;

		.MuiInputBase-root {
			padding: 0 14px 0 16px;

			&.Mui-focused {
				//
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-primary;
				}
			}

			&.Mui-error {
				//
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-red;
				}
			}
		}

		.MuiInputBase-input {
			padding: 10px 0;
		}

		.MuiSelect-select {
			color: $c-black;
			font-weight: $text-weight-normal;
		}

		.MuiFormHelperText-root {
			width: 100%;
			margin: 3px 0 0;
			color: $c-red;
			word-break: break-all;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.MuiOutlinedInput-notchedOutline {
			border-radius: 4px;
			border: solid 1px $c-grey;
		}
	}

	&--placeholder {
		.MuiFormControl-root {
			.MuiSelect-select {
				color: $c-light-grey;
			}
		}
	}
}

.app-select-input-modal {
	&__hidden {
		display: none !important;
	}

	.MuiPaper-root {
		max-height: 300px;

		.MuiFormControl-root {
			width: 100%;

			.MuiInputBase-root {
				padding: 0;

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}

			.MuiInputBase-input {
				padding: 10px 0;
			}

			.MuiOutlinedInput-notchedOutline {
				border: 0 !important;
			}
		}
	}
}
