.app-notifications-view-recipients-modal {
	display: flex;
	align-items: center;
	justify-content: center;

	.view-recipients-modal {
		width: 60%;
		height: max-content;
		max-height: 975px;
		padding: 16px 24px 32px 24px;
		border-radius: 4px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;
		position: relative;

		&__title {
			font-size: $text-size-l;
			color: $c-deep-blue;
			font-weight: $text-weight-bold;
			margin: 0 0 16px 0;
		}

		&__table {
			width: 100%;
		}

		&__close-container {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			position: relative;
		}

		&__close-button {
			width: 30px;
			margin: 8px 0 0 0;
			border: none;
			background: none;
			padding: 0;
			cursor: pointer;
		}

		@include max-width($scr-sm) {
			min-width: 250px;
		}
	}
}
