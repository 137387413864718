.app-customer-asset-list-table {
	//
	.customer-asset-list-table {
		//
		&__header {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			@include max-width($scr-w-xs) {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		&__title {
			color: $c-deep-blue;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__button {
			display: flex;
			justify-content: flex-end;

			.app-button {
				width: 150px;
				padding: 10px;
				flex-direction: row-reverse;

				&--outline {
					background-color: transparent;
					border: 1px solid $c-secondary;

					.app-button__label {
						color: $c-secondary;
					}
				}
			}
		}

		.app-table {
			margin: 15px 0;

			&__cell {
				border-bottom: 0 !important;

				.app-checkbox {
					margin-left: auto;
					margin-right: auto;
				}

				&--header {
					background-color: $c-header-blue;

					.app-checkbox__box {
						//
						svg {
							//
							path {
								stroke: $c-header-blue;
							}
						}
					}
				}

				&--header-text {
					text-wrap: nowrap;
				}
			}

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.customer-asset-list-table-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}
}
