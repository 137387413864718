.app-leave-calendar {
	width: 100%;

	.leave-calendar {
		display: flex;
		flex-direction: column;

		&__employee-leave-obeservances {
			margin-bottom: 10px;
		}

		&__item {
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			gap: 10px;
		}

		&__holiday {
			display: flex;
		}

		&__faq-icon {
			width: 20px;
			height: 20px;
			margin: 0 0 0 5px;
			justify-content: center;
		}
	}
}
