.app-work-order-services {
	width: 100%;
	max-width: $scr-w-lg;

	.work-order-services {
		//
		.app-table-filter-header {
			//
			.app-input {
				margin: 0;

				.MuiOutlinedInput-notchedOutline {
					border-radius: 5px;
					border: 1px solid $c-light-grey;
				}
			}
		}

		&__container {
			padding: 25px 0;
			margin-bottom: 15px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__label {
			margin: 0 25px 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;

			&--services {
				//
				@include max-width($scr-xs) {
					margin: 15px 0;
				}
			}
		}

		&__required {
			color: $c-red;
		}

		&__text {
			margin: 15px 0;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__row {
			padding: 0 25px;
			display: flex;
			justify-content: space-between;

			@include max-width($scr-xs) {
				flex-direction: column;
			}

			.app-button {
				border: 1px solid $c-secondary;
				background-color: transparent !important;
				display: flex;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}

			&--divider {
				padding: 0 0 20px 0;
				margin: 0 0 25px 0;
				border-bottom: 2px solid $c-border;
			}
		}

		&__column {
			width: 100%;
			padding: 0 25px;

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;

			.app-button {
				width: auto;
				padding: 10px 15px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}

			.app-table-filter-header {
				width: 100%;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}

		&__item-table {
			margin: 15px 0;
			background-color: $c-light-blue;
			border: 3px solid $c-border;
			border-radius: 5px;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);

			.item-table {
				display: flex;
				flex-direction: row;
				border: 1px solid $c-header-blue;
				border-bottom: 0;

				&__content {
					scrollbar-width: none;
					overflow: auto;
					flex: 1;
				}

				&__switch-service {
					display: flex;
					flex-direction: column;
					justify-content: space-around;

					.app-button {
						background: transparent;
					}
				}

				&__item {
					display: flex;
					flex-direction: row;
					border-bottom: 1px solid $c-header-blue;

					&--subtasks {
						background-color: $c-white;
					}
				}

				&__label {
					width: 150px;
					margin: 0;
					padding: 15px;
					color: $c-grey;
					font-size: $text-size-s;
					border-right: 1px solid $c-header-blue;

					&--subtasks {
						width: calc((100% - 150px) / 2);
					}
				}

				&__value {
					flex: 1;
					@extend .item-table__label;

					&--underline {
						text-decoration: underline;
					}
				}

				&__pagination {
					width: 70px;
					padding: 20px 10px;
					display: flex;
					align-items: center;
					flex-direction: column;
					justify-content: space-between;
				}

				&__button {
					border: 0;
					width: 35px;
					cursor: pointer;
					background-color: transparent;

					&--prev {
						transform: rotate(270deg);

						&:disabled {
							opacity: 0.1;
						}
					}

					&--next {
						transform: rotate(90deg);

						&:disabled {
							opacity: 0.1;
						}
					}

					img {
						width: 100%;
					}
				}

				&__page {
					text-align: center;
					color: $c-light-grey;
					font-size: $text-size-s;
				}
			}
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.work-order-services-table-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}
}
