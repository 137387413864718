.app-add-services-linkage-modal {
	width: 100%;
	padding: 36px;
	display: flex;
	justify-content: center;

	.add-services-linkage-modal {
		max-width: 1024px;
		padding: 36px;
		border-radius: 4px;
		background-color: $c-white;
		overflow: scroll;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* Internet Explorer 10+ */

		&__title {
			color: $c-primary;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 0 0 8px 0;
		}

		&__highlight {
			color: $c-green;
		}

		&__header {
			margin: 0 0 8px 0;
			display: flex;
		}

		&__search-input {
			width: 100%;
			max-width: 550px;
			display: flex;

			.app-input {
				margin: 0;

				.MuiOutlinedInput-notchedOutline {
					border-radius: 5px;
					border: 1px solid $c-light-grey;
				}
			}
		}

		&__filter-button {
			margin: 0 10px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}

.add-services-linkage-modal-menu {
	//
	.MuiPaper-root {
		width: 100%;
		max-width: 300px;
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		@include max-width($scr-sm) {
			width: calc(100% - 32px);
		}

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid $c-light-grey !important;
		}
	}

	.filter-menu {
		padding: 10px 20px;

		&__body {
			margin-bottom: 20px;
		}

		&__content {
			display: flex;
			flex-direction: column;
		}

		&__checkbox {
			//
			.app-checkbox {
				margin: 0 0 5px;
			}
		}

		&__label {
			margin: 0 0 5px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			background-color: $c-white;
		}

		&__footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__buttons {
			display: flex;
			align-items: center;
		}

		&__button {
			color: $c-red;
			font-size: $text-size-s;
			transition: $transition;
			border: 0;
			background-color: transparent;
			cursor: pointer;

			&:hover {
				color: darken($c-red, 10%);
			}

			& ~ .filter-menu__button {
				margin-left: 10px;
			}

			&--cancel {
				color: $c-light-grey;

				&:hover {
					color: darken($c-light-grey, 10%);
				}
			}

			&--apply {
				color: $c-secondary;

				&:hover {
					color: darken($c-secondary, 10%);
				}
			}
		}
	}
}
