.app-enquiries-kpi-configuration-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.enquiries-kpi-configuration-modal {
		width: 100%;
		max-width: 525px;
		padding: 30px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: bold;
		}

		&__header {
			margin: 0;
			padding: 0 15px;
			color: $c-grey;
			font-weight: bold;
		}

		&__form {
			width: 100%;
		}

		&__description {
			margin: 0;
			padding: 5px 15px;

			span {
				color: $c-red;
			}
		}

		&__time-picker {
			padding: 15px 0 0 12px;
		}

		&__button-container {
			width: 320px;
			margin: 30px auto 0;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
