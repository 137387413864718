.page-work-order-create-edit {
	width: 100%;
	max-width: $scr-w-md;

	.work-order-create-edit {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__last-update {
			margin: 0;
			white-space: pre;
			color: $c-black;
			font-size: $text-size-s;
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__header-button {
			gap: 10px;
			display: flex;
			flex-direction: row-reverse;

			@include max-width($scr-w-xs) {
				margin: 0 0 0 10px;
				flex-direction: column-reverse;
			}

			.app-button {
				width: auto;
				padding: 8px 15px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__action-button {
			//
			.app-icon {
				transform: rotate(90deg);
			}
		}
	}
}

.work-order-create-edit-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.menu {
			color: $c-grey;

			&__item {
				font-size: $text-size-xs;
				gap: 5px;
				display: flex;
				flex-direction: row-reverse;
			}

			img {
				width: 20px;
				height: 20px;
			}
		}
	}
}
