.app-open-enquiries-table {
	//
	.open-enquiries-table {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				&__response-time {
					margin: 0;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;

					&--green {
						color: $c-green;
					}

					&--red {
						color: $c-red;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.open-enquiries-table-filter {
	width: 100%;
	min-width: 500px;
	display: flex;
	gap: 20px;
	flex-direction: column;

	@include max-width($scr-xs) {
		min-width: 100%;
	}

	&__container {
		display: flex;
		flex-direction: column;

		.app-input-date {
			margin-bottom: 0;
		}
	}

	&__inputs {
		display: flex;
		justify-content: space-between;

		.app-input-date {
			width: calc(50% - 2px);
			margin: 0 0 5px;

			& ~ .app-input-date {
				align-items: flex-end;
			}
		}

		@include max-width($scr-w-xs) {
			flex-direction: column;
			align-items: flex-start;

			.app-input-date {
				width: 100%;
				margin-bottom: 20px;
			}
		}
	}

	&__checkbox-status {
		display: flex;

		.app-checkbox {
			margin: 10px 10px 5px;
		}

		@include max-width($scr-w-xs) {
			flex-direction: column;
		}
	}

	&__label {
		margin: 0;
		padding: 0 0 0 10px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__instruction {
		margin: 0;
		color: $c-light-grey;
		font-size: $text-size-s;

		&--error {
			color: $c-red;
		}
	}
}
