.app-otp-verification-modal {
	display: flex;
	align-items: center;
	justify-content: center;

	.otp-verification-modal {
		width: 100%;
		max-width: 580px;
		padding: 20px;
		border-radius: 8px;
		background-color: $c-white;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		&__close {
			margin-left: auto;
			border: none;
			cursor: pointer;
			background-color: transparent;
		}

		&__title {
			margin: 15px 0 0;
			color: $c-primary;
			font-size: $text-size-xl;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 5px 0 0;
			text-align: center;
		}

		&__form {
			display: flex;
			align-items: center;
			flex-direction: column;
		}

		&__inputs {
			margin: 25px 0 0;
			display: flex;
			flex-direction: row;

			.app-input {
				width: 50px;
				height: 50px;
				min-width: inherit;
				max-width: 100%;
				margin: 0 10px;

				.MuiFormControl-root {
					height: 100%;

					.MuiInputBase-root {
						height: 100%;
					}

					.MuiOutlinedInput-notchedOutline {
						border: 1px solid $c-border;
					}

					.MuiInputBase-input {
						text-align: center;
						font-size: $text-size-l;
					}
				}
			}
		}

		&__error {
			margin: 5px 0;
			color: $c-red;
			font-size: $text-size-s;

			&--hide {
				color: transparent;
			}
		}

		&__timer {
			color: $c-light-grey;
			font-size: $text-size-s;
		}

		&__resend-button {
			color: $c-secondary;
			font-size: $text-size-s;
			text-align: center;
			cursor: pointer;
		}
	}
}
