.app-pending-claim-table {
	//
	.pending-claim-table {
		//
		&__header-button {
			display: flex;
			justify-content: end;

			.app-button {
				width: 100%;
				justify-content: center;
			}
		}

		&__approve-button {
			//
			.app-button {
				padding: 8px 20px;
				flex-direction: row-reverse;
				background-color: $c-green;
				border: 1px solid $c-green;
			}
		}

		&__reject-button {
			margin: 0 10px 0 0;

			.app-button {
				padding: 8px 20px;
				border: 1px solid $c-red;
				flex-direction: row-reverse;

				&__label {
					color: $c-red;
				}
			}
		}
	}

	.app-table {
		margin: 15px 0;

		&__body {
			margin-left: auto;
			margin-right: auto;
		}

		&__cell {
			border-bottom: 0 !important;

			.app-checkbox {
				margin-left: auto;
				margin-right: auto;
			}

			&--header {
				background-color: $c-header-blue;

				.app-checkbox__box {
					//
					svg {
						//
						path {
							stroke: $c-header-blue;
						}
					}
				}
			}
		}

		.table {
			//
			&__view {
				width: 100%;
				height: 28px;
				border: 0;
				background-color: transparent;
				cursor: pointer;
			}

			.MuiTableCell-head {
				//
				&:last-child {
					text-align: center;
				}
			}
		}
	}
}
