.app-view-upcoming-ongoing-employee-leave-table {
	//
	.view-upcoming-ongoing-employee-leave-table {
		//
		&__container {
			margin: 25px 0;
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				.MuiTableCell-body {
					//
					&:first-child {
						max-width: 120px;
						background-color: $c-background;
						border-right: 1px solid $c-header-blue;
					}
				}
			}
		}
	}
}
