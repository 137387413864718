.page-user-access-edit-user {
	//
	.user-access-edit-user {
		//
		&__details {
			margin: 10px 0;
			display: flex;
			flex-direction: row;
		}

		&__image {
			position: relative;
			width: 100px;
			border: 1px solid #ffffff;
			border-radius: 100px;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
			display: flex;
			flex-direction: row;
		}

		&__picture {
			width: 100%;
			border-radius: 100px;
		}

		&__camera {
			position: absolute;
			width: 30px;
			height: 30px;
			right: 0;
			bottom: 0;
			border: none;
			background: transparent;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;

			&--disabled {
				cursor: not-allowed;
			}
		}

		&__icon {
			width: 30px;
		}

		&__name {
			margin-left: 15px;
			color: $c-dark-grey;
			font-size: $text-size-l;
			font-weight: $text-weight-normal;
			align-self: center;
		}
	}
}
