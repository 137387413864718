.app-employee-directory-table {
	//
	.employee-directory-table {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.employee-directory-table-filter {
	width: 100%;
	min-width: 300px;
	display: flex;
	flex-direction: row;
	gap: 20px;

	&__container {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	&__label {
		margin: 0 0 5px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
		background-color: $c-white;
	}
}

.employee-directory-table-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}
}
