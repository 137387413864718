.app-table-footer {
	display: flex !important;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	&__rows {
		color: $c-dark-grey;
		font-size: $text-size-s;
	}

	&__page {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	&__current {
		color: $c-dark-grey;
		font-size: $text-size-s;
	}

	&__buttons {
		margin: 0 0 0 10px;
		display: flex;

		.MuiButtonBase-root {
			padding: 0;

			svg {
				fill: $c-dark-grey;
			}
		}
	}
}
