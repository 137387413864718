.app-notifications-scheduled-table {
	//
	.notifications-scheduled-table {
		//
		&__header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		}

		&__search-input {
			width: 100%;
			max-width: 550px;
			display: flex;
			flex-direction: row;

			.app-input {
				margin: 0;
				border-radius: 5px;
				background-color: $c-white;

				.MuiOutlinedInput-notchedOutline {
					border: 0;
				}
			}
		}

		&__filter-button {
			margin: 0 10px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__create-button {
			//
			.app-button {
				max-width: 125px;
				padding: 8px 20px;
				flex-direction: row-reverse;
			}
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.notifications-scheduled-table-filter-menu {
	//
	.MuiPaper-root {
		width: 100%;
		max-width: 600px;
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		@include max-width($scr-sm) {
			width: calc(100% - 32px);
		}

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid $c-light-grey !important;
		}
	}

	.filter-menu {
		padding: 10px 20px;

		&__body {
			margin-bottom: 20px;

			@include max-width($scr-sm) {
				flex-direction: column;
			}
		}

		&__row {
			display: flex;
			justify-content: space-between;

			@include max-width($scr-md) {
				flex-direction: column;
			}
		}

		&__container {
			width: calc(50% - 10px);

			@include max-width($scr-md) {
				width: 100%;
			}

			.app-input,
			.app-input-date,
			.app-input-multi-select {
				width: 100%;
				margin: 0 0 5px;
			}
		}

		&__inputs {
			//
			&--dates {
				flex-direction: column;
			}
		}

		&__instruction {
			margin: 0;
			color: $c-light-grey;
			font-size: $text-size-s;
		}

		&__footer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		&__buttons {
			padding: 0 0 0 15px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		&__button {
			color: $c-red;
			font-size: $text-size-s;
			transition: $transition;
			border: 0;
			background-color: transparent;
			cursor: pointer;

			&:hover {
				color: darken($c-red, 10%);
			}

			& ~ .filter-menu__button {
				margin-left: 10px;
			}

			&--cancel {
				color: $c-light-grey;

				&:hover {
					color: darken($c-light-grey, 10%);
				}
			}

			&--apply {
				color: $c-secondary;

				&:hover {
					color: darken($c-secondary, 10%);
				}
			}
		}
	}
}
