.page-customer-edit-asset {
	width: 100%;
	max-width: $scr-w-md;

	.customer-edit-asset {
		//
		&__header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__last-update {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__title {
			color: $c-black;
			font-size: $text-size-xl;
		}
	}
}
