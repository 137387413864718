.page-view-employee-leave {
	//
	.view-employee-leave {
		//
		&__title {
			font-size: $text-size-xl;
		}

		&__header {
			margin-top: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.app-status {
				//
				.status {
					//
					&__text {
						font-size: $text-size-m;
					}
				}
			}
		}

		&__id {
			margin: 0;
		}
	}
}
