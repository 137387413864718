.page-sales-order-create-edit {
	width: 100%;
	max-width: $scr-w-md;

	.sales-order-create-edit {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		&__header {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;

			@include max-width($scr-xs) {
				margin-bottom: 20px;
				align-items: flex-start;
				flex-direction: column;
			}

			&--column {
				flex-direction: column;
			}
		}

		&__header-buttons {
			width: 100%;
			max-width: 320px;
			gap: 10px;
			display: flex;
			flex-direction: row-reverse;

			.app-button {
				width: 100%;
				max-width: 160px;
				height: 100%;
				padding: 8px 15px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__drop-off-button {
			width: 100%;

			.app-button {
				border: 1px solid $c-red;

				&__label {
					color: $c-red;
				}
			}
		}

		&__last-update {
			margin: 15px 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}
	}
}
