.page-enquiries-create-edit {
	width: 100%;
	max-width: $scr-w-md;

	.enquiries-create-edit {
		//
		&__header {
			margin: 15px 0;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-size: $text-size-xl;
		}

		&__actions {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

		&__last-update {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__container {
			margin-bottom: 20px;
			padding: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-input,
			.app-mobile-input,
			.app-select-input {
				width: calc(50% - 20px);

				&--full-width {
					width: 100%;
				}
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-mobile-input,
				.app-select-input {
					width: 100%;
				}
			}

			&--divider {
				margin-bottom: 25px;
				border-bottom: 1px solid $c-border;
			}
		}

		&__remark {
			//
			.app-input {
				width: 100%;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
