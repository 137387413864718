.app-input-drag-and-drop {
	width: 100%;
	margin-bottom: 25px;

	.input-drag-and-drop {
		padding: 25px;
		border-radius: 8px;
		background-color: $c-white;
		box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;

		&--error {
			border: 1px solid $c-red;
		}

		&--dragover {
			border: 2px dashed $c-green;
		}

		&__icon {
			width: 25px;
			height: 25px;

			img {
				width: 100%;
			}
		}

		&__display {
			width: 100%;

			img {
				width: 100%;
			}
		}

		&__download-button,
		&__remove-button {
			width: 25px;
			height: 25px;
			padding: 0;
			margin: 5px 0 0 auto;
			border: 0;
			background-color: transparent;
			color: $c-secondary;
			cursor: pointer;

			img {
				width: 100%;
			}
		}

		&__placeholder {
			color: $c-grey;
			font-size: $text-size-s;
		}

		&__button {
			color: $c-secondary;
			cursor: pointer;
		}
	}
}
