.page-work-inspection-listing {
	//
	.work-inspection-listing {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.work-inspection-listing-filter {
	width: 100%;
	max-width: 600px;
	display: flex;
	gap: 20px;
	flex-direction: column;

	@include max-width($scr-w-xs) {
		width: calc(100% - 32px);
	}

	&__container {
		display: flex;
		flex-direction: column;
	}

	&__inputs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.app-input-date {
			width: calc(50% - 2px);
			margin: 0 0 5px;

			& ~ .app-input-date {
				align-items: flex-end;
			}
		}

		@include max-width($scr-w-xs) {
			flex-direction: column;
			align-items: flex-start;

			.app-input-date {
				width: 100%;
			}
		}
	}

	&__instruction {
		margin: 0;
		color: $c-light-grey;
		font-size: $text-size-s;

		&--error {
			color: $c-red;
		}
	}

	&__label {
		margin: 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__checkbox-status {
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		@include max-width($scr-w-xs) {
			display: flex;
			flex-direction: column;
		}

		.app-checkbox {
			margin: 10px 10px 5px 0;
		}
	}
}
