.app-table {
	//
	.table {
		//
		&__empty-state {
			//
		}

		&__empty-container {
			padding: 45px;
		}

		&__empty-image {
			width: 100%;
			max-width: 420px;
		}

		&__empty-label {
			color: $c-dark-grey;
		}

		.MuiPaper-root {
			box-shadow: 0px 0px 10.7px 0px rgba(163, 166, 172, 0.2);

			.MuiTableCell-head {
				padding: 15px 20px;
				background-color: $c-header-blue;
				border: 0;

				.MuiButtonBase-root {
					padding: 0;
					min-width: inherit;
					white-space: nowrap;
					text-transform: capitalize;
				}
			}

			.MUIDataTable-responsiveBase {
				overflow: visible;
			}

			.MuiButtonBase-root {
				display: flex;
				align-items: center;

				div {
					color: $c-grey;
					font-size: $text-size-s;
				}

				.MuiTableSortLabel-root {
					width: 20px;
					height: 20px;
					margin: auto;
					display: flex;
					align-items: center;

					svg {
						//
						path {
							color: $c-dark-grey;
						}
					}

					&.Mui-active {
						background: none;
						justify-content: center;
					}
				}
			}

			.MuiTableCell-root {
				//
				> div {
					color: $c-grey;
					font-size: $text-size-s;
				}
			}

			.MuiTableCell-body {
				padding: 10px 20px;
				background-color: $c-white;
				border-bottom: 1px solid $c-header-blue;
			}

			.MuiTableCell-footer {
				padding: 18px;
				background-color: $c-footer-grey;
				border: 0;
			}
		}
	}
}
