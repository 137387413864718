.app-side-nav {
	width: $side-nav-width;
	min-height: 100vh;
	transition: $transition;
	flex-shrink: 0;

	@include max-width($scr-sm) {
		position: fixed;
		width: 0;
		margin-top: 70px;
		z-index: 1299;
	}

	.side-nav {
		height: 100vh;
		padding: 15px 0 25px;
		background-repeat: no-repeat;
		background-image: url("../../assets/images/components/app-side-nav/background.png");

		@include max-width($scr-sm) {
			background-size: 100% 100%;
		}

		&__logo {
			width: 150px;
			height: 85px;
			margin: 0 40px;
			cursor: pointer;
			transition: $transition;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url("../../assets/images/components/app-side-nav/homepage-logo.png");

			@include max-width($scr-sm) {
				width: 0;
				margin: 0 0 0 15px;
			}
		}

		&__nav {
			position: relative;
			height: calc(100% - 60px);
			overflow-x: hidden;
			overflow-y: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			display: flex;
			flex-direction: column;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}
		}

		&__list {
			margin: 0;
			padding: 0 0 0 20px;
			transition: $transition;
		}

		&__item {
			position: relative;
			margin: 10px 0;
			width: 100%;
			min-width: 200px;
			cursor: pointer;
			z-index: 999;
			pointer-events: none;
			list-style-type: none;
			display: flex;
			flex-direction: column;
			justify-content: center;
			transition: $transition;
		}

		&__wrapper {
			height: 55px;
			color: $c-white;
			text-decoration: none;
			white-space: nowrap;
			pointer-events: auto;
			display: flex;
			align-items: center;

			&--active {
				//
				&:after {
					transform: rotate(180deg);
				}
			}

			&--no-chevron {
				//
				&::after {
					display: none;
				}
			}

			&:after {
				content: "";
				width: 25px;
				height: 25px;
				margin-right: 20px;
				margin-left: auto;
				background-size: contain;
				background-position: center;
				background-repeat: no-repeat;
				background-image: url("../../assets/images/chevron-down-icon.png");
			}
		}

		&__content {
			display: flex;
			flex-direction: row;
			transition: $transition;
		}

		&__icon {
			width: 23px;
			height: 23px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;

			svg {
				width: 100%;
				height: 100%;
				flex-shrink: 0;
			}
		}

		&__children {
			width: 240px;
			margin: 15px 0;
			padding: 0 23px;
			pointer-events: auto;

			&:hover {
				.side-nav__label {
					color: $c-yellow;
				}
			}
		}

		&__label {
			margin: 2.5px 0 0 10px;
			color: $c-white;
			pointer-events: none;
			white-space: pre-line;
			font-size: $text-size-s;
			font-weight: $text-weight-normal;
			transition: $transition;

			&--active {
				color: $c-white;
			}

			&--child-active {
				color: $c-yellow;
			}
		}

		&__background {
			position: absolute;
			height: 55px;
			width: 100%;
			top: 0;
			right: 0;
			left: 0;
			background-color: $c-secondary;
			transition: $transition;
			z-index: 888;
		}
	}

	&--shrinked {
		width: $side-nav-shrinked-width;

		@include max-width($scr-sm) {
			width: 100%;

			.side-nav {
				//
				&__logo {
					width: inherit;
				}
			}
		}

		@include min-width($scr-sm) {
			.side-nav {
				&__logo {
					margin: 0px -46px;
					transform: scale(0.35);
				}

				&__list {
					padding: 0 0 0 17px;
				}

				&__content {
					align-items: center;
				}

				&__label {
					opacity: 0;
				}
			}
		}
	}
}
