.app-inventory-history-view-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.inventory-history-view-modal {
		width: 100%;
		max-height: 90%;
		max-width: 600px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__form {
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
		}

		&__image {
			margin: 0 0 20px;
			display: flex;
			justify-content: center;

			img {
				width: 160px;
				height: 160px;
				border-radius: 5px;
			}
		}

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__row {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-input,
			.app-select-input,
			.app-input-date-time {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-select-input,
				.app-input-date-time {
					width: 100%;
				}
			}
		}

		&__signature {
			width: calc(50% - 20px);
			display: flex;
			flex-direction: column;

			img,
			.app-input,
			.app-input-drag-and-drop {
				width: 100%;
			}
		}

		&__file-title {
			color: $c-primary;
			font-weight: $text-weight-semibold;
		}

		&__button-container {
			max-width: 320px;
			margin: 0 auto 10px;
			display: flex;
			flex-direction: row;
			gap: 15px;
		}

		&__approve-button {
			width: 100%;

			.app-button {
				background-color: $c-green;
				border: 1px solid $c-green;
			}
		}

		&__issue-button {
			width: 100%;
		}

		&__reject-button {
			width: 100%;
			margin: 0 10px 0 0;

			.app-button {
				border: 1px solid $c-red;

				&__label {
					color: $c-red;
				}
			}
		}
	}
}
