.app-inventory-adjust-stock-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.inventory-adjust-stock-modal {
		width: 100%;
		max-width: 600px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
		}

		&__text {
			margin-bottom: 15px;
			padding: 0 24px;
			text-align: center;
			color: $c-dark-grey;
			font-size: $text-size-s;
		}

		&__form {
			width: 100%;
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input {
					width: 100%;
				}
			}
		}

		&__table-container {
			display: flex;
			justify-content: center;
		}

		&__table {
			width: 70%;
			border-collapse: collapse;

			th,
			td {
				height: 50px;
				width: calc(100% / 3);
				border: 1px solid $c-light-grey;
				padding: 0 16px;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px auto 0;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
