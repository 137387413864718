.app-work-order-work-details {
	width: 100%;
	max-width: $scr-w-md;

	.work-order-work-details {
		//
		&__header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.app-button {
				width: auto;
				margin: 0 0 10px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__description {
			//
			&--attachment {
				font-weight: bold;
			}
		}

		&__container {
			margin-bottom: 20px;
			padding: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__wrapper {
			padding: 0 25px;

			&--new-record {
				margin: 0 0 20px;
				padding: 0;
				border: 2px solid $c-header-blue;
			}

			&--collapsed {
				padding: 0;
				border: none;
			}
		}

		&__inner-wrapper {
			margin: 20px 0;
			padding: 0 25px;
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__record-header {
			display: flex;
			justify-content: space-between;
		}

		&__button {
			width: 100%;
			max-width: 120px;
		}

		&__header-label {
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__category {
			margin: 0 0 20px;
			padding: 5px 25px;
			color: $c-primary;
			font-weight: $text-weight-semibold;
			background-color: $c-header-blue;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		&__header-button {
			display: flex;
			flex-direction: row;
			gap: 30px;
		}

		&__toggle-button {
			width: 10px;
			margin: 0;
			border: 0;
			padding: 0;
			background-color: transparent !important;

			img {
				height: 25px;
			}
		}

		&__delete-record-button {
			width: 10px;
			margin: 0;
			border: 0;
			padding: 0;
			background-color: transparent !important;
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.app-input,
			.app-select-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-md) {
				flex-direction: column;

				.app-input,
				.app-select-input {
					width: 100%;
				}
			}
		}

		&__attachments {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;
		}

		&__column {
			width: calc(50% - 20px);
		}

		&__add-record-button {
			width: auto;
			margin: 0 0 15px;
			padding: 10px 15px;
			border: 1px solid $c-secondary;
			background-color: transparent !important;
			display: flex;
			flex-direction: row-reverse;

			&--outline {
				border: 0;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
