.app-input-time {
	position: relative;
	width: 100%;
	min-width: 150px;
	margin-bottom: 25px;

	&--disabled {
		//
		.MuiInputBase-root {
			background-color: #f8f8f8;
		}
	}

	&__label {
		padding: 0 0 0 14px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
		background-color: $c-white;
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	.MuiInputLabel-root {
		color: $c-light-grey;

		&.Mui-focused {
			color: $c-focused-blue;
		}
	}

	.MuiInputAdornment-root {
		margin: 0;
	}

	.MuiFormControl-root {
		width: 100%;
		justify-content: space-between;

		.MuiInputLabel-root {
			color: $c-light-grey;
		}

		.MuiInputBase-root {
			padding: 0 5px 0 0;
			color: $c-black;
			border-radius: 0;
			font-weight: $text-weight-normal;

			&.Mui-focused {
				//
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-primary;
				}
			}

			&.Mui-error {
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-red;
				}
			}
		}

		.MuiOutlinedInput-notchedOutline {
			border-radius: 5px;
			border: 1px solid $c-primary;
		}

		.MuiInputBase-input {
			padding: 10px 0 10px 10px;

			&::placeholder {
				opacity: 1;
				color: $c-light-grey;
			}
		}

		.MuiIconButton-root {
			padding: 5px;
			margin-right: 0 !important;
		}

		.MuiFormHelperText-root {
			width: 100%;
			margin: 3px 0 0;
			color: $c-red;
			word-break: break-all;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}
