@mixin max-width($width) {
	@media only screen and (max-width: $width) {
		@content;
	}
}

@mixin min-width($width) {
	@media only screen and (min-width: $width) {
		@content;
	}
}
