.app-customer-asset-list {
	width: 100%;
	max-width: $scr-w-md;

	.customer-asset-list {
		//
		&__container {
			padding: 25px;
			margin-bottom: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__title {
			margin: 0 0 15px;
			color: $c-deep-blue;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__instructions {
			font-size: $text-size-body;
			color: $c-grey;
		}

		&__button {
			display: flex;
			justify-content: center;

			.app-button {
				width: 200px;
				padding: 10px;
				flex-direction: row-reverse;

				&--outline {
					background-color: transparent;
					border: 1px solid $c-secondary;

					.app-button__label {
						color: $c-secondary;
					}
				}
			}
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			@include max-width($scr-xs) {
				flex-direction: column;
			}
		}

		&__chevron {
			height: 28px;
			border: 0;
			background-color: transparent;
			cursor: pointer;

			img {
				height: 100%;
			}
		}
	}
}
