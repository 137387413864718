.app-user-change-password {
	//
	.user-change-password {
		//
		&__form {
			//
		}

		&__container {
			padding: 40px;
			background: $c-white;
			display: flex;
			flex-direction: column;
		}

		&__row {
			display: flex;
			justify-content: space-between;

			.app-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-md) {
				flex-direction: column;

				.app-input {
					width: 100%;
				}
			}
		}

		&__column {
			width: calc(50% - 20px);

			.app-input {
				width: 100%;
			}

			@include max-width($scr-md) {
				width: 100%;
			}
		}

		&__icon {
			margin: 0 5px 0 0;
		}

		&__description {
			color: $c-dark-grey;
			font-size: $text-size-xs;
			display: flex;
			align-items: center;
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
