.app-user-access-checklist {
	display: flex;
	flex-direction: column;
	width: 944px;
	margin: 24px 0 16px 0;
	background-color: #ffffff;

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 8px 174px 0 48px;
		color: $c-dark-grey;

		&__label {
			display: flex;
			flex-direction: row;
		}

		&__actions {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			text-align: center;
			width: 290px;
		}
	}

	.module {
		display: flex;
		flex-direction: column;
		margin: 8px 174px 8px 24px;

		&__header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			height: 56px;
			width: 896px;
			background: #d6edf8;
			cursor: pointer;

			.label {
				font-weight: $text-weight-bold;
				margin: 0 0 0 24px;
			}

			.options {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin: 0 16px 0 0;

				.select-all {
					color: $c-secondary;
					font-size: $text-size-s;
					font-weight: $text-weight-normal;
					width: 58px;
					line-height: 24px;
					letter-spacing: 0.07px;
					margin: 0 16px 0 0;

					&:hover {
						color: $c-focused-blue;
					}
				}

				.chevron {
					width: 24px;

					&.close {
						transform: rotate(0deg);
						transition: $transition;
					}

					&.open {
						transform: rotate(180deg);
						transition: $transition;
					}
				}
			}
		}

		&__children {
			width: 896px;
			margin: 16px 0 0 0;
			max-height: 0;
			overflow: hidden;
			transition: max-height $transition;

			&.open {
				max-height: 1000px;
			}

			.child {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin: 0 0 24px 0;

				&__label {
					margin: 0 0 0 24px;
				}

				&__actions {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					width: 290px;
					margin: 0 150px 0 0;

					.disabled {
						.app-checkbox {
							cursor: default;
							&__box {
								background-color: $c-disabled;
							}
						}
					}

					.app-checkbox {
						margin: 0 12.5px 0 12.5px;
						&__label {
							margin: 0;
						}
					}
				}
			}
		}
	}
}
