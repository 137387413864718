.app-view-employee-leave-history-table {
	//
	.view-employee-leave-history-table {
		//
		&__header {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 10px;

			.app-table-filter-header {
				width: 100%;
			}
		}

		&__header-button {
			display: flex;
			gap: 5px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}
			}
		}
	}

	.app-table {
		margin: 15px 0;

		&__body {
			margin-left: auto;
			margin-right: auto;
		}

		&__cell {
			border-bottom: 0 !important;

			.app-checkbox {
				margin-left: auto;
				margin-right: auto;
			}

			&--header {
				background-color: $c-header-blue;

				.app-checkbox__box {
					//
					svg {
						//
						path {
							stroke: $c-header-blue;
						}
					}
				}
			}
		}

		.table {
			//
			&__view {
				width: 100%;
				height: 28px;
				border: 0;
				background-color: transparent;
				cursor: pointer;
			}

			.MuiTableCell-head {
				//
				&:last-child {
					text-align: center;
				}
			}
		}
	}
}

.view-employee-leave-history-table-filter {
	width: 100%;
	max-width: 600px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	@include max-width($scr-w-xs) {
		width: calc(100% - 32px);
	}

	&__container {
		display: flex;
		flex-direction: column;
	}

	&__inputs {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.app-input-date {
			width: calc(50% - 2px);
			margin: 0 0 5px;

			& ~ .app-input-date {
				align-items: flex-end;
			}
		}

		@include max-width($scr-w-xs) {
			flex-direction: column;
			align-items: flex-start;

			.app-input-date {
				width: 100%;
			}
		}
	}

	.filter-menu {
		//
		&__instruction {
			margin: 0;
			color: $c-light-grey;
			font-size: $text-size-s;

			&--error {
				color: $c-red;
			}
		}
	}

	&__label {
		margin: 0;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
	}

	&__checkbox-status {
		display: grid;
		grid-template-columns: repeat(4, 1fr);

		@include max-width($scr-w-xs) {
			display: flex;
			flex-direction: column;
		}

		.app-checkbox {
			margin: 10px 10px 5px 0;
		}
	}
}
