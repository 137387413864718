.app-hr-view-holidays-create-edit-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.hr-view-holidays-create-edit-modal {
		width: 100%;
		max-width: 480px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__form {
			width: 100%;
		}

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
		}

		&__radio-input {
			//
			.app-radio-input {
				//
				&__radios {
					padding: 0;
					flex-wrap: nowrap;
					gap: 10px;

					@include max-width($scr-xs) {
						width: 100%;
						flex-direction: column;
					}
				}

				&__item {
					width: 100%;
				}
			}
		}

		&__row {
			display: flex;
			flex-direction: row;
			gap: 10px;

			@include max-width($scr-sm) {
				width: 100%;
				flex-direction: column;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 30px auto 0;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
