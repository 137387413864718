.page-activity-logs-view-details {
	width: 944px;

	.activity-logs-view-details {
		.activity-logs {
			&__activity-details {
				margin: 0 0 16px 0;

				.details-header {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					padding: 16px 32px 16px 16px;
					margin: 0 0 16px 0;
					border-radius: 4px;
					background-color: $c-white;
					box-shadow: 0px 2px 12px 0px rgba(163, 166, 172, 0.2);

					&__left {
						display: flex;
						flex-direction: row;
						align-items: center;
						width: 944px;

						.profile-picture {
							display: flex;
							flex-direction: row;
							width: 56px;
							height: 56px;
							border: 1px solid #ffffff;
							box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
							border-radius: 56px;

							.picture {
								border-radius: 56px;
							}
						}

						.user-details {
							height: 56px;
							margin: 0 0 0 16px;

							&__user-name {
								font-weight: $text-weight-bold;
								font-size: $text-size-s;
							}

							&__user-id {
								font-size: $text-size-xs;
							}

							&__user-role {
								font-size: $text-size-xs;
							}
						}
					}

					&__right {
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						width: 159px;
						font-size: 12px;

						.activity-date {
							display: flex;
							justify-content: flex-end;
						}

						.activity-time {
							display: flex;
							justify-content: flex-end;
						}
					}

					.details-table {
					}
				}
			}

			&__activity-data {
			}
		}
	}
}
