.page-user-access-create-edit-role {
	width: 100%;

	.user-access-create-edit-role {
		//
		&__header {
			margin: 15px 0;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
		}

		&__last-update {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-size: $text-size-xl;
		}

		&__container {
			padding: 25px;
			margin-bottom: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			&--divider {
				margin-bottom: 25px;
				border-bottom: 1px solid $c-border;
				flex-wrap: wrap;
			}

			.app-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input {
					width: 100%;
				}
			}
		}

		&__column {
			width: calc(50% - 20px);

			.app-input {
				width: 100%;
			}

			@include max-width($scr-xs) {
				width: 100%;
			}
		}

		&__toggle {
			display: flex;
			align-items: center;

			.app-toggle-switch {
				margin-left: 0;
			}
		}

		&__label {
			padding: 0 0 0 14px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		.roles {
			//
			&__header {
				//
				.roles__items {
					background-color: $c-white;
				}
			}

			&__permissions-header {
				display: flex;

				@include max-width($scr-w-md) {
					display: none;
				}
			}

			&__permissions-body {
				display: flex;
				flex-wrap: wrap;
			}

			&__body {
				display: flex;
				flex-direction: column;
			}

			&__accordion {
				padding-bottom: 10px;
			}

			&__category-container {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				background-color: #d6edf8;

				.app-button {
					max-width: 130px;
					padding: 12.5px 25px;
					border: 0;
					background-color: transparent;

					&__label {
						color: $c-secondary;
						font-size: $text-size-s;
					}
				}
			}

			&__category {
				margin: 0;
				padding: 12.5px 25px;
				color: $c-primary;
				font-weight: $text-weight-semibold;
			}

			&__items {
				padding: 0 25px;
				background-color: #f4f8fc;
				display: flex;
				flex-direction: row;

				@include max-width($scr-w-md) {
					flex-direction: column;
				}
			}

			&__item {
				width: 100%;
				min-width: 150px;
				flex: 1;
			}

			&__action {
				width: 80px;
				display: flex;
				align-items: center;
				justify-content: center;

				@include max-width($scr-w-md) {
					justify-content: flex-start;
				}

				.app-checkbox {
					margin: 5px 0;

					&__label {
						color: $c-dark-grey;

						@include min-width($scr-w-md) {
							display: none;
						}
					}

					&--active {
						//
						.app-checkbox__box {
							background-color: $c-primary;
							border: 1px solid $c-primary;
						}
					}
				}
			}

			&__label {
				margin: 15px 0;
				color: $c-primary;
				font-size: $text-size-s;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
