.page-leave-management {
	//
	.leave-management {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}

		&__header {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__configuration-button {
			//
			.app-button {
				padding: 10px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}
	}
}
