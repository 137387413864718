.app-inventory-history-confirmation-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.inventory-history-confirmation-modal {
		width: 100%;
		max-height: 90%;
		max-width: 500px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			margin: 0;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}
	}
}
