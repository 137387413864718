.page-inventory-history {
	//
	.inventory-history {
		//
		&__title {
			margin: 0 0 10px;
			font-size: $text-size-xl;
		}
	}
}
