.app-upload-image {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-family: $font-family;
	font-style: normal;

	&__title {
		color: $c-deep-blue;
		font-size: $text-size-l;
		font-weight: $text-weight-bold;
		line-height: 24px;
		margin-bottom: 10px;
	}

	&__description {
		width: 369px;
		color: $c-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-regular;
		text-align: center;
		margin-bottom: 16px;
	}

	&__upload-container {
		display: flex;
		flex-direction: column;
		// justify-content: space-between;

		&--hidden {
			display: none;
		}

		.upload-wrapper {
			display: flex;
			flex-direction: column;
			align-items: center;
			.upload-details {
				display: flex;
				flex-direction: row;
				justify-content: center;
				width: 365px;
				margin: 0 0 24px 0;

				&__file-icon {
					align-self: center;
					margin-right: 10px;
				}

				&__file-detail {
					width: 60%;

					.detail {
						display: flex;
						width: 100%;
					}

					&--file-name {
						width: 75%;
						color: $c-grey;
						font-size: $text-size-xs;
						font-weight: $text-weight-normal;
						line-height: 18px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					&--file-size {
						width: 25%;
						color: #c0c3ca;
						font-size: $text-size-xs;
						font-weight: $text-weight-normal;
						line-height: 18px;
						text-align: right;

						img {
							margin-right: 10px;
							cursor: pointer;
						}
					}

					// &.MuiBox-root css-1udd4ti {
					// 	position: absolute;
					// 	top: 50%;
					// 	left: 50%;
					// 	transform: translate(-50%, -50%);
					// 	background-color: white;
					// 	border: 1px solid #000;
					// 	box-shadow: 24;

					// 	img {
					// 		width: 400px;
					// 		height: 400px;
					// 	}
					// }
				}

				&__progress {
					border-radius: 5px;
					.css-5xe99f-MuiLinearProgress-bar1 {
						background-color: #44af69;
					}
				}

				&__remove-icon {
					align-self: center;
					margin-left: 5px;
					cursor: pointer;
				}
			}
		}
	}

	.app-button {
		display: flex;
		width: 114px;
		height: 40px;
		padding: 11px 24px;
		justify-content: center;
		align-items: center;
		border-radius: 4px;
		cursor: pointer;
	}

	&__notification {
		display: flex;
		align-items: center;

		input {
			position: relative;
			top: -2px;
			width: 14px;
			height: 14px;
			vertical-align: middle;
			border: 1px solid $c-light-grey;
			cursor: pointer;
			margin-right: 8px;
		}

		input:checked {
			background-color: $c-secondary;
		}

		label {
			display: block;
			padding-left: 15px;
			text-indent: -15px;
			color: $c-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-regular;
			line-height: normal;
		}
	}

	&__error {
		margin: 4px 0 0 0;
		font-size: $text-size-xs;
		color: $c-red;
	}
}
