.app-status {
	//
	.status {
		//
		&__text {
			margin: 0;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&--green {
			//
			.status__text {
				color: #44af69;
			}
		}

		&--yellow {
			//
			.status__text {
				color: #ffb904;
			}
		}

		&--red {
			//
			.status__text {
				color: #dc4c4c;
			}
		}

		&--grey {
			//
			.status__text {
				color: rgba(192, 195, 202, 1);
			}
		}

		&--orange {
			//
			.status__text {
				color: $c-orange;
			}
		}

		&--blue {
			//
			.status__text {
				color: $c-secondary;
			}
		}

		&--deep-blue {
			//
			.status__text {
				color: $c-deep-blue;
			}
		}
	}
}
