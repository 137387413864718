.page-view-attendance {
	//
	.view-attendance {
		//
		&__title {
			margin: 0;
			color: $c-grey;
			font-size: $text-size-xl;
		}

		&__header {
			margin: 15px 0 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.app-status {
				//
				.status {
					//
					&__text {
						margin: 0;
						font-size: $text-size-m;
					}
				}
			}
		}

		&__id {
			margin-top: 0;
			color: $c-grey;
			font-size: $text-size-m;
		}
	}
}
