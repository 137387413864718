.app-inventory-history-issue-component {
	//
	.inventory-history-issue-component {
		//
		&__text {
			margin: 20px 0;
			color: $c-dark-grey;
			text-align: center;

			span {
				color: $c-secondary;
				font-weight: $text-weight-semibold;
			}
		}

		&__title {
			color: $c-primary;
			font-weight: $text-weight-semibold;

			span {
				color: $c-red;
			}
		}

		.app-input-drag-and-drop {
			//
			.input-drag-and-drop {
				//
				&__display {
					//
					img {
						max-width: 300px;
						max-height: 300px;
						display: block;
						margin: auto;
					}
				}
			}
		}

		&__button-container {
			width: 100%;
			max-width: 240px;
			margin: 10px auto 0;
			display: flex;
			flex-direction: row;
			gap: 15px;

			.app-button {
				//
				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
