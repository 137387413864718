.app-input-multi-select {
	position: relative;
	width: 100%;
	min-width: 150px;
	margin-bottom: 25px;
	display: flex;

	&--disabled {
		//
		.MuiFormControl-root {
			background-color: #f8f8f8;
		}
	}

	&__label {
		padding: 0 0 0 14px;
		color: $c-dark-grey;
		font-size: $text-size-s;
		font-weight: $text-weight-semibold;
		background-color: $c-white;
	}

	&__placeholder {
		margin-top: 15px;
		color: $c-light-grey !important;

		&--no-label {
			margin-top: -7px;
		}
	}

	&__required {
		color: $c-red;
		line-height: 0;
	}

	&__root {
		.MuiMenuItem-root {
			border-bottom: 1px solid $c-border !important;
		}
	}

	&--placeholder {
		.MuiFormControl-root {
			.MuiSelect-select {
				color: $c-light-grey;
			}
		}
	}

	.MuiFormControl-root {
		width: 100%;

		.MuiInputBase-root {
			padding: 0 14px 0 0;
			color: $c-black;
			border-radius: 0;
			font-weight: $text-weight-normal;

			&.Mui-focused {
				//
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-primary;
				}
			}

			&.Mui-error {
				.MuiOutlinedInput-notchedOutline {
					border: 1px solid $c-red;
				}
			}
		}

		.MuiInputBase-input {
			padding: 10px 0 10px 16px;

			&::placeholder {
				color: $c-light-grey;
			}
		}

		.MuiOutlinedInput-notchedOutline {
			border-radius: 5px;
			border: 1px solid $c-primary;
		}

		.MuiFormHelperText-root {
			width: 100%;
			margin: 3px 0 0;
			color: $c-red;
			word-break: break-all;
			font-size: $text-size-xs;
			font-weight: $text-weight-normal;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

.app-input-multi-select-popup {
	//
	&__chevron {
		width: 15px;
		display: flex;
		align-items: center;

		img {
			width: 100%;
		}
	}

	&__sub-options {
		padding: 0 15px 0 0;
	}

	&__option {
		display: flex;
		align-items: center;
	}

	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);
		//
		.MuiList-root {
			padding: 0;

			.MuiMenuItem-root {
				padding: 10px;
				border-bottom: 1px solid $c-border;
			}

			.Mui-checked {
				color: $c-tertiary;
			}

			.MuiCheckbox-root {
				margin: 0 5px 0 0;

				.MuiButtonBase-root {
					padding: 0;

					.MuiSvgIcon-root {
						border-radius: 8px;
					}
				}
			}
		}

		.MuiFormControl-root {
			width: 100%;

			.MuiInputBase-root {
				padding: 0;

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}

			.MuiInputBase-input {
				padding: 10px 0;
			}

			.MuiOutlinedInput-notchedOutline {
				border: 0 !important;
			}
		}
	}
}
