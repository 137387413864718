.page-employee-maintenance-create-edit {
	width: 100%;
	max-width: $scr-w-md;

	.employee-maintenance-create-edit {
		//
		&__header {
			margin: 15px 0;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
		}

		&__last-update {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;

			span {
				color: $c-light-grey;
				font-size: $text-size-s;
			}
		}

		&__title {
			margin: 0;
			color: $c-black;
			font-size: $text-size-xl;
		}
	}
}
