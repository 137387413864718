.app-claim-summary-table {
	//
	.claim-summary-table {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				.MuiTableCell-body {
					padding: 10px 20px;
					border-bottom: 1px solid $c-header-blue;

					&:first-child {
						max-width: 120px;
						background-color: $c-background;
						border-right: 1px solid $c-header-blue;
					}
				}
			}
		}
	}
}
