.app-user-successful-modal {
	display: flex;
	align-items: center;
	justify-content: center;

	.user-successful-modal {
		padding: 24px;
		border-radius: 8px;
		background-color: $c-white;
		display: flex;
		align-items: center;
		flex-direction: column;

		&__header {
			display: flex;
			justify-content: center;
		}

		&__title {
			margin: 0 0 15px;
			color: $c-deep-blue;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 0;
			color: $c-dark-grey;
			text-align: center;
			font-size: $text-size-s;
		}

		&__container {
			width: 100%;
			margin: 20px 0 0;
			padding: 15px;
			border-radius: 8px;
			background: $c-footer-grey;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__label {
			margin: 0;
			color: $c-black;
			font-size: $text-size-s;
			display: flex;
			flex: 1;
		}

		&__value {
			margin: 0 0 0 8px;
			color: $c-black;
			font-size: $text-size-s;
			display: flex;
			flex: 1;
		}

		&__content {
			display: flex;

			&:last-child {
				margin-top: 10px;
			}
		}

		&__details {
			font-size: $text-size-s;
			display: flex;
			flex-direction: column;
			flex: 1;
		}

		&__copy-button {
			width: auto;
			border: 0;
			background-color: transparent;
			display: flex;
			flex-direction: row-reverse;

			.app-button {
				//
				&__label {
					color: $c-secondary;
					font-weight: $text-weight-semibold;
				}
			}
		}

		&__footer {
			width: 100%;
			margin: 15px 0 0;

			.app-button {
				width: 100%;
				max-width: 145px;
				min-width: inherit;
				margin: 0 auto;
			}
		}
	}
}
