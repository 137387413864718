.app-employee-directory-view-contact-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.employee-directory-view-contact-modal {
		width: 100%;
		max-width: 550px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			margin: 0;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
		}

		&__container {
			margin: 10px 20px 0;
		}

		&__header {
			margin: 0 0 10px;
			color: $c-dark-grey;
			font-weight: $text-weight-semibold;
		}

		&__box {
			width: 100%;
			margin: 0 0 10px;
			padding: 5px;
			background-color: $c-pale-grey;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
		}

		&__details {
			width: 100%;
			margin: 0 auto;
			display: flex;
			flex-direction: row;
		}

		&__text {
			width: 100%;
			margin: 5px 0 0 20px;
			color: $c-dark-grey;

			&--bold {
				font-weight: $text-weight-semibold;
			}
		}

		&__button {
			max-width: 160px;
			margin: 20px auto 0;
		}
	}
}
