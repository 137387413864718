.app-corrective-attendance-history-employee-table {
	//
	.corrective-attendance-history-employee-table {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				&__view {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}

				.MuiTableCell-body {
					//
					&:nth-child(2) {
						border-left: 1px solid $c-header-blue;
						background-color: $c-background;
					}

					&:nth-child(4),
					&:nth-child(7) {
						border-right: 1px solid $c-header-blue;
						background-color: $c-background;
					}

					&:nth-child(3),
					&:nth-child(5),
					&:nth-child(6) {
						background-color: $c-background;
					}
				}
			}
		}
	}
}
