.app-report-details-asset-table {
	//
	.report-details-asset-table {
		//
		&__header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			.app-button {
				width: auto;
				padding: 10px 15px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
					white-space: nowrap;
				}

				.app-icon {
					width: 20px;
					height: 20px;
				}
			}
		}

		&__label {
			margin: 0;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		.app-table {
			margin: 15px 0;

			&__cell {
				border-bottom: 0 !important;
	
				.app-checkbox {
					margin-left: auto;
					margin-right: auto;
				}
	
				&--header {
					background-color: $c-header-blue;
	
					.app-checkbox__box {
						//
						svg {
							//
							path {
								stroke: $c-header-blue;
							}
						}
					}
				}
			}

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.table-menu {
	//
	.MuiPaper-root {
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiMenuItem-root {
			color: $c-grey;
			font-size: $text-size-s;

			img {
				width: 20px;
				height: 20px;
				margin: 0 5px 0 0;
			}
		}
	}

	&__remove {
		color: $c-red;
	}
}
