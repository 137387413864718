.app-site-details-team-members-table {
	width: 100%;

	.site-details-team-members-table {
		//
		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;
		}

		&__header {
			margin: 15px 0;
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: flex-end;
			justify-content: space-between;
		}

		&__add-button {
			margin: 0 10px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}
			}
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__delete {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				&__text {
					color: $c-light-grey;
					font-size: $text-size-s;
				}

				&__link {
					margin-left: 5px;
					color: $c-secondary;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
					cursor: pointer;
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}
