.app-hr-view-employee-directory-table {
	//
	.hr-view-employee-directory-table {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				&__view {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}

				.MuiTableCell-body {
					//
					&:nth-child(4) {
						border-right: 1px solid $c-header-blue;
					}
				}
			}
		}
	}
}
