* {
	color: $c-black;
	font-weight: normal;
	font-family: $font-family;
	font-size: $text-size-body;
	outline: none;
}

html,
body {
	height: 100%;
	min-width: 320px;
}

body {
	background-color: $c-white;
}

input[type="number"] {
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
