.app-report-details {
	width: 100%;
	max-width: $scr-w-md;

	.report-details {
		//
		&__container {
			margin-bottom: 20px;
			padding: 25px;
			background-color: $c-white;
			box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
		}

		&__label {
			margin: 0 0 15px;
			color: $c-primary;
			font-size: $text-size-body;
			font-weight: $text-weight-semibold;

			&--table {
				margin: 0;
			}
		}

		&__row {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			justify-content: space-between;

			.app-input,
			.app-input-date,
			.app-input-time,
			.app-select-input,
			.app-mobile-input {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-input-date,
				.app-input-time,
				.app-select-input,
				.app-mobile-input {
					width: 100%;
				}
			}

			&--signature {
				//
				@include max-width($scr-md) {
					flex-direction: column;
				}
			}
		}

		&__column {
			width: calc(50% - 20px);

			.app-input,
			.app-select-input {
				width: 100%;
			}

			&--signature {
				//
				.app-input-drag-and-drop {
					margin-bottom: 10px;
				}

				@include max-width($scr-md) {
					width: 100%;
					margin: 0 0 20px;
				}
			}

			&--acknowledgedBy-approval {
				width: 100%;
				padding: 25px;
				border-radius: 8px;
				background-color: $c-white;
				box-shadow: 0px 0px 10.7px rgba(163, 166, 172, 0.2);
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}
		}

		&__button-container {
			max-width: 320px;
			margin: 15px 0 0 auto;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
