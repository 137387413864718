.app-customer-confirm-mobile-account-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.customer-confirm-mobile-account-modal {
		width: 100%;
		max-height: 90%;
		max-width: 460px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__description {
			margin: 5px 0;
			color: $c-dark-grey;
			text-align: center;
		}

		&__button-container {
			max-width: 320px;
			margin: 30px auto 0;
			display: flex;
			flex-direction: row;

			.app-button {
				//
				& ~ .app-button {
					margin-left: 15px;
				}

				&--outline {
					border: 0;
					background-color: transparent;
				}
			}
		}
	}
}
