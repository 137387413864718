.app-change-profile-pic-modal {
	display: flex;
	align-items: center;
	justify-content: center;

	.change-profile-pic-modal {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 580px;
		height: max-content;
		padding: 16px 32px 32px 32px;
		background-color: white;
		border-radius: 4px;

		.main {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			&__close-container {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				width: 100%;
				.close {
					background: none;
					border: none;
					padding: 0;
					cursor: pointer;
				}
			}

			&__title {
				text-align: center;
				font-size: 24px;
				color: $c-deep-blue;
				margin: 0 0 16px 0;
			}

			&__description {
				text-align: center;
				width: 352px;
				margin: 0 0 16px 0;
				font-size: $text-size-s;
			}

			&__button {
				display: flex;
				justify-content: center;

				.app-button {
					border-radius: 4px;
					width: 114px;
				}
			}
		}
	}
}
