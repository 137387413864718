.page-activity-logs {
	display: flex;
	flex-direction: column;
	width: 944px;

	.activity-logs {
		display: flex;
		flex-direction: column;

		&__title {
			width: 513px;
			height: 43px;
			margin: 0 0 24px 0;
			font-size: 36px;
			font-weight: $text-weight-normal;
		}

		&__header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin: 0 0 8px 0;

			.filter-section {
				width: 685px;
				position: relative;
				display: flex;
				flex-direction: row;
				// justify-content: space-between;
				align-items: center;

				&__search {
					.app-input {
						outline: none;
						margin: 0 8px 0 0;

						.MuiInputBase-root {
							width: 450px;
							height: 32px;
							padding: 0;
							background-color: $c-white;
						}

						.MuiOutlinedInput-notchedOutline {
							border: none;
						}
					}

					.app-icon {
						cursor: default;
						padding: 0 14px 0 0;
					}
				}

				&__filter {
					.filter-menu {
						display: flex;
						flex-direction: column;
						justify-items: center;
						align-items: center;
						position: absolute;
						z-index: 1;
						left: 0px;
						margin: 8px 0 0 0;
						padding: 16px;
						width: 538px;
						height: 274px;
						border-radius: 4px;
						background: $c-white;
						box-shadow: 0px 2px 12px 0px rgba(163, 166, 172, 0.2);

						&__inputs {
							display: flex;
							flex-direction: column;

							.app-input {
								&.user {
									width: 245px;
									height: 60px;
									margin: 2px 0 0 16px;

									.MuiInputBase-input {
										font-size: $text-size-body;
									}

									.app-input {
										&__label {
											color: $c-black;
											font-size: 12px;
											font-weight: 500;
											margin: 0 0 7px 8px;
										}
									}

									.MuiOutlinedInput-notchedOutline {
										border: 1px solid $c-light-grey;
									}

									.MuiInputBase-root {
										height: 39px;
									}
								}

								&.created-date {
									width: 245px;
									height: 60px;
								}
							}

							.first-row {
								display: flex;
								flex-direction: row;

								.date-pickers {
									width: 245px;

									&__title {
										color: $c-black;
										font-size: 12px;
										font-weight: 500;
										margin: 0 0 7px 8px;
									}

									&__inputs {
										display: flex;
										justify-content: space-between;
									}

									&__instruction {
										margin: 4px 0 4px 10px;
										color: $c-light-grey;
										font-size: $text-size-xxs;
										font-weight: $text-weight-regular;

										&.error {
											color: $c-red;
										}
									}
								}
							}

							.second-row {
								display: flex;
								flex-direction: row;
								margin: 0 0 0 10px;

								.checkBoxFilterInputs {
									&__title {
										font-size: $text-size-xs;
										font-weight: $text-weight-semibold;
										margin: 0 0 4px 0;
									}

									&__activities {
										display: flex;
										flex-wrap: wrap;
										justify-content: center;

										.activity {
											display: flex;
											flex-direction: row;
											justify-content: space-between;
											position: relative;
											height: 18px;
											margin: 0 0 18px 0;
											padding: 0 32px 0 0;

											&__title {
												font-size: $text-size-xs;
											}

											&__clickable {
												display: flex;
												flex-direction: row;

												.selected-amount {
													display: flex;
													justify-content: center;
													width: 18px;
													height: 18px;
													border-radius: 50%;
													font-size: $text-size-xs;
													background-color: #c0c3ca;
													color: $c-white;
												}

												.chevron {
													display: flex;
													justify-content: center;
													align-items: center;
													.app-icon {
														width: 12px;
														height: 12px;
													}
												}

												:hover {
													cursor: pointer;
												}
											}

											&__checkbox-list {
												position: absolute;
												z-index: 3;
												display: flex;
												flex-direction: column;
												justify-content: space-between;
												width: max-content;
												height: max-content;
												left: 136px;
												border-radius: 0px 4px 4px 4px;
												padding: 0px 4px;
												box-shadow: 0px 2px 12px 0px rgba(163, 166, 172, 0.2);
												background: $c-white;

												.checkbox-filter {
													margin: 5px 0;
												}
											}
										}
									}

									&__activities > div {
										flex-basis: 33.33%;
										margin: 0 0 4px 0;

										.app-checkbox {
											&__label {
												font-size: $text-size-xs;
												font-weight: $text-weight-regular;
												color: $c-dark-grey;
											}
										}
									}
								}
							}
						}

						&__options {
							display: flex;
							flex-direction: row;
							justify-content: space-between;
							width: 506px;
							margin: 6px 0 16px 0px;
							font-size: $text-size-xs;

							.left {
								display: flex;
								flex-direction: row;

								&__clear {
									color: $c-red;
									cursor: pointer;
								}
							}

							.right {
								display: flex;
								flex-direction: row;

								&__cancel {
									margin: 0 16px 0 0;
									color: $c-light-grey;
									cursor: pointer;
								}

								&__apply {
									color: $c-secondary;
									cursor: pointer;

									&.disabled {
										color: $c-disabled;
										cursor: not-allowed;
									}

									.text {
										&.disabled {
											pointer-events: none;
										}
									}
								}
							}
						}
					}

					.app-button-light {
						flex-direction: row-reverse;
						width: 77px;
						height: 32px;
						border: 1px solid $c-secondary;
						&__label {
							color: $c-secondary;
							margin: 0 4px 0 0;
						}

						&.active {
							.app-button-light {
								background: rgba(14, 161, 252, 0.2);
							}
						}
					}
				}

				&__filter-time-range {
					margin: 0 0 0 8px;

					.app-select-input {
						.MuiFormControl-root {
							.MuiInputBase-root {
								width: 128px;
								height: 32px;
							}
							.MuiOutlinedInput-notchedOutline {
								border: 1px solid $c-secondary;
							}
						}

						.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
							text-overflow: unset;
							width: 70px;
							font-size: $text-size-s;
							color: $c-secondary;
						}

						.MuiSvgIcon-root {
							color: $c-secondary;
						}
					}
				}
			}

			.export-excel {
				.app-button-light {
					flex-direction: row-reverse;
					justify-content: space-between;
					width: 110px;
					height: 17px;
					padding: 0;
					margin: 0;
					background-color: $c-background;
					&__label {
						font-size: $text-size-s;
						font-weight: $text-weight-semibold;
						color: $c-secondary;
					}
					.app-icon {
						width: 16px;
						height: 16px;
					}
				}
			}
		}

		&__table {
			.tss-1cdcmys-MUIDataTable-responsiveBase {
				// overflow: visible;
			}

			.tss-1vd39vz-MUIDataTableBodyCell-stackedCommon {
				.view-activity {
					display: flex;
					flex-direction: row;
					justify-content: center;
					:hover {
						cursor: pointer;
					}
				}
			}
		}
	}
}
