.app-category-table {
	//
	.category-table {
		//
		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}
			}
		}
	}
}

.category-table-filter {
	width: 100%;
	min-width: 500px;
	display: flex;
	gap: 20px;
	flex-direction: row;

	@include max-width($scr-xs) {
		min-width: 100%;
		flex-direction: column;
	}

	&__container {
		gap: 8px;
		display: flex;
		flex-direction: column;
	}

	&__label {
		margin: 0;
		padding: 0 0 5px 10px;
		color: $c-dark-grey;
		font-size: 14px;
		font-weight: 600;
	}
}
