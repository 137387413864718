.app-corrective-attendance-view-modal {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.corrective-attendance-view-modal {
		width: 100%;
		max-height: 90%;
		max-width: 600px;
		padding: 25px;
		border-radius: 10px;
		background-color: $c-white;
		display: flex;
		flex-direction: column;

		&__close {
			margin-left: auto;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}

		&__title {
			margin: 0 0 20px;
			color: $c-primary;
			text-align: center;
			font-size: $text-size-l;
			font-weight: $text-weight-semibold;
		}

		&__container {
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
		}

		&__row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.app-input,
			.app-input-date {
				width: calc(50% - 20px);
			}

			@include max-width($scr-xs) {
				flex-direction: column;

				.app-input,
				.app-input-date {
					width: 100%;
				}
			}
		}

		&__chevron {
			//
			@include max-width($scr-xs) {
				transform: rotate(90deg);
			}
		}
	}
}
