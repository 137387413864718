.app-layout {
	width: 100%;
	min-width: 320px;
	background: $c-background;

	.main {
		width: 100%;
		display: flex;
		flex-direction: row;
		overflow: hidden;

		&__container {
			position: relative;
			width: calc(100% - $side-nav-width);
			max-height: 100vh;
			border-top-left-radius: 25px;
			border-bottom-left-radius: 25px;
			background-color: $c-background;
			transition: $transition;
			display: flex;
			flex-direction: column;

			&--shrinked {
				width: calc(100% - $side-nav-shrinked-width);
			}

			@include max-width($scr-sm) {
				width: 100%;
				z-index: 1298;
				border-radius: 0;
				min-height: 100vh;
			}
		}

		&__page {
			max-height: calc(100vh - $header-height);
			padding: 50px;
			overflow: scroll;
			scrollbar-width: none; /* Firefox */
			-ms-overflow-style: none; /* Internet Explorer 10+ */
			flex: 1;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			@include max-width($scr-sm) {
				max-height: calc(100vh - 120px);
			}
		}
	}
}
