.app-services-linkage-table {
	//
	.services-linkage-table {
		//
		&__header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		}

		&__search-input {
			width: 100%;
			max-width: 550px;
			display: flex;

			.app-input {
				margin: 0;

				.MuiOutlinedInput-notchedOutline {
					border-radius: 5px;
					border: 1px solid $c-light-grey;
				}
			}
		}

		&__filter-button {
			margin: 0 10px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__interact {
			display: flex;
		}

		&__interact-button {
			//
			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;
				flex-direction: row-reverse;

				&__label {
					color: $c-secondary;
				}
			}

			& ~ .services-linkage-table__interact-button {
				margin-left: 10px;
			}
		}

		.app-table {
			margin: 15px 0;

			.table {
				//
				&__action {
					width: 100%;
					height: 28px;
					border: 0;
					background-color: transparent;
					cursor: pointer;

					img {
						height: 100%;
					}
				}

				.MuiTableCell-head {
					//
					&:last-child {
						text-align: center;
					}
				}

				&__empty-state {
					//
				}

				&__text {
					color: $c-light-grey;
					font-size: $text-size-s;
				}

				&__link {
					margin-left: 5px;
					color: $c-secondary;
					font-size: $text-size-s;
					font-weight: $text-weight-semibold;
					cursor: pointer;
				}
			}
		}
	}
}

.services-linkage-table-menu {
	//
	.MuiPaper-root {
		width: 100%;
		max-width: 300px;
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		@include max-width($scr-xs) {
			width: calc(100% - 32px);
		}

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid $c-light-grey !important;
		}
	}

	.filter-menu {
		padding: 10px 20px;

		&__body {
			margin-bottom: 20px;
			display: flex;
			justify-content: space-around;
		}

		&__content {
			display: flex;
			flex-direction: column;
		}

		&__label {
			margin: 0 0 5px;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
		}

		&__inputs {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: start;
			justify-content: space-between;

			.app-checkbox {
				margin-bottom: 4px;
			}
		}

		&__footer {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&__buttons {
			display: flex;
			align-items: center;
		}

		&__button {
			color: $c-red;
			font-size: $text-size-s;
			transition: $transition;
			border: 0;
			background-color: transparent;
			cursor: pointer;

			&:hover {
				color: darken($c-red, 10%);
			}

			& ~ .filter-menu__button {
				margin-left: 10px;
			}

			&--cancel {
				color: $c-light-grey;

				&:hover {
					color: darken($c-light-grey, 10%);
				}
			}

			&--apply {
				color: $c-secondary;

				&:hover {
					color: darken($c-secondary, 10%);
				}
			}
		}
	}
}
