.app-user-applications-table {
	//
	.user-applications-table {
		//
		&__header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 10px;
		}

		&__search-input {
			width: 100%;
			max-width: 550px;
			display: flex;
			flex-direction: row;

			.app-input {
				margin: 0;
				border-radius: 5px;
				background-color: $c-white;

				.MuiOutlinedInput-notchedOutline {
					border: 0;
				}
			}
		}

		&__filter-button {
			margin: 0 10px;

			.app-button {
				padding: 8px 20px;
				background-color: transparent;
				border: 1px solid $c-secondary;

				&__label {
					color: $c-secondary;
				}
			}
		}

		&__header-button {
			display: flex;

			.app-button {
				width: 100%;
				justify-content: center;
			}
		}

		&__approve-button {
			//
			.app-button {
				padding: 8px 20px;
				flex-direction: row-reverse;
				background-color: $c-green;
				border: 1px solid $c-green;
			}
		}

		&__reject-button {
			margin: 0 10px 0 0;

			.app-button {
				padding: 8px 20px;
				border: 1px solid $c-red;
				flex-direction: row-reverse;

				&__label {
					color: $c-red;
				}
			}
		}
	}

	.app-table {
		margin: 15px 0;

		&__body {
			margin-left: auto;
			margin-right: auto;
		}

		&__cell {
			border-bottom: 0 !important;

			.app-checkbox {
				margin-left: auto;
				margin-right: auto;
			}

			&--header {
				background-color: $c-header-blue;

				.app-checkbox__box {
					//
					svg {
						//
						path {
							stroke: $c-header-blue;
						}
					}
				}
			}
		}
	}
}

.app-user-applications-table-filter-menu {
	//
	.MuiPaper-root {
		width: 600px;
		border-radius: 8px;
		box-shadow: 0px 0px 10px 0px rgba(163, 166, 172, 0.2);

		.MuiOutlinedInput-notchedOutline {
			border: 1px solid $c-light-grey !important;
		}
	}

	.filter-menu {
		padding: 10px 20px;

		&__body {
			margin-bottom: 20px;

			.app-button {
				color: black;
			}
		}

		&__title {
			margin: 0 10px 0;
			color: $c-dark-grey;
			font-size: $text-size-s;
			font-weight: $text-weight-semibold;
			background-color: $c-white;
		}

		&__instruction {
			margin: 0;
			color: $c-light-grey;
			font-size: $text-size-s;

			&--error {
				color: $c-red;
			}
		}

		&__inputs {
			display: flex;
			justify-content: space-between;

			.app-input-date {
				width: calc(50% - 20px);
				margin: 0 0 5px;

				& ~ .app-input-date {
					align-items: flex-end;
				}
			}

			@include max-width(500px) {
				flex-direction: column;
				align-items: flex-start;

				.app-input-date {
					width: 100%;
					margin-bottom: 20px;
				}
			}
		}

		&__footer {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}

		&__buttons {
			padding: 0 0 0 15px;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		&__button {
			color: $c-red;
			font-size: $text-size-s;
			transition: $transition;
			border: 0;
			background-color: transparent;
			cursor: pointer;

			&:hover {
				color: darken($c-red, 10%);
			}

			& ~ .filter-menu__button {
				margin-left: 10px;
			}

			&--cancel {
				color: $c-light-grey;

				&:hover {
					color: darken($c-light-grey, 10%);
				}
			}

			&--apply {
				color: $c-secondary;

				&:hover {
					color: darken($c-secondary, 10%);
				}
			}
		}
	}
}
