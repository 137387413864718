.app-layout-alert {
	position: absolute;
	margin: 0 10px;
	top: 50px;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 9999999999;

	.layout-alert {
		opacity: 0;
		padding: 10px 20px;
		border: 1px solid $c-green;
		border-radius: 100px;
		background-color: $c-white;
		transition: $transition;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&__icon {
			width: 18px;
			height: 18px;
			margin: 0 5px;
		}

		&__text {
			margin: 0;
			color: $c-green;
			font-weight: $text-weight-semibold;
			font-size: $text-size-xs;
		}
	}

	&--active {
		//
		.layout-alert {
			opacity: 1;
		}
	}

	&--error {
		//
		.layout-alert {
			border: 1px solid $c-red;

			&__text {
				color: $c-red;
			}
		}
	}
}
